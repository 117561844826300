
import { window, document } from '@/libs/globalUtils'

import GAEventTracker from '@/libs/GAEventTracker'
import UniversalCookie from './UniversalCookie'
import { capitalize } from './utils'

const TESTING = 'testing'
const ORIGIN = 'origin'

const EXP_EXPLORE = 'explore'

const ABTESTING_RANDOM_NUM = 'ABTESTING_RANDOM_NUM_v2'

export default {
  config({ isMobile, randomNum }) {
    this.isMobile = isMobile
    this.randomNum = randomNum
    // Use cookie to stablize the random number
    const storedRandomNum = UniversalCookie.get(ABTESTING_RANDOM_NUM)
    if (storedRandomNum == undefined) {
      UniversalCookie.set(ABTESTING_RANDOM_NUM, randomNum, {
        expires: new Date(Date.now() + 30 * 60 * 1000),
        path: '/',
      })
    } else {
      this.randomNum = storedRandomNum
    }
  },
  getRandomNum() {
    return this.randomNum
  },
  sendEvent({ exp }) {
    if (!this.randomNum) {
      return
    }
    if (this.isMobile) {
      const abTesting = this.getABTesting({ exp })
      if (exp == EXP_EXPLORE) {
        GAEventTracker.setUserProps({
          ab_test_group: abTesting
        })
      }
      GAEventTracker.sendEventWithParams({
        event: `${capitalize(exp)} AB ${capitalize(abTesting)}`,
        params: {ab_test_group: abTesting}
      })
    }
    return
  },
  getABTesting({ exp }) {
    if (!this.randomNum) {
      return ORIGIN
    }
    if (exp == EXP_EXPLORE) {
      if (!this.isMobile) {
        return ORIGIN
      }
      if (this.randomNum % 2 == 0) {
        return TESTING
      } else {
        return ORIGIN
      }
    }
    return ORIGIN
  },
  TESTING,
  ORIGIN,
  EXP_EXPLORE,
}

