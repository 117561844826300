import { fromJS } from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/app'
import dayjs from 'dayjs'

const OVERSECOND_FOR_DOWNLOAD_BANNER_VISIBLE = 600

export const initialState = fromJS({
  _hasInit: false,

  isDownloadBannerVisible: false,
  randomNum: 100,
  lang: '',
  mobileStatus: {},
})

const REDUCER_NODE = 'app'

export default function app(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)
    case ActionType.SET_DOWNLOAD_BANNER_STATUS:
      return state.update((state) => {
        let clickTime = dayjs(action.value)
        let nowTime = dayjs()
        return state.set(
          'isDownloadBannerVisible',
          nowTime.diff(clickTime, 'seconds') >=
            OVERSECOND_FOR_DOWNLOAD_BANNER_VISIBLE
        )
      })

    case ActionType.GET_DOWNLOAD_BANNER_STATUS:
      return state.update((state) => {
        let clickTime = dayjs(
          typeof action.localData === 'undefined'
            ? 0
            : parseInt(action.localData)
        )
        let nowTime = dayjs()
        return state.set(
          'isDownloadBannerVisible',
          nowTime.diff(clickTime, 'seconds') >=
            OVERSECOND_FOR_DOWNLOAD_BANNER_VISIBLE
        )
      })

    case ActionType.SET_RANDOM_NUM:
      return state.set('randomNum', fromJS(action.randomNum))

    case ActionType.SET_LANG:
      return state.set('lang', fromJS(action.lang))

    case ActionType.SET_MOBILE_STATUS:
      return state.set('mobileStatus', fromJS(action.mobileStatus))

    default:
      return state
  }
}
