import { createTheme } from '@mui/material/styles'
import ifoodieTheme from '@/libs/ifoodieTheme'

const theme = createTheme(ifoodieTheme)

function createPageContext() {
  return {
    theme,
  }
}

export default function getPageContext() {
  if (!process.browser) {
    return createPageContext()
  }
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext()
  }

  return global.__INIT_MATERIAL_UI__
}
