import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/post'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  blogData: {},
  postData: {},
  blogTags: [],
  recentPosts: [],
})

const REDUCER_NODE = 'post'

export default function post(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_POST_SUCCESS:
      return state
        .set('blogData', Immutable.fromJS(action.response.info.blog))
        .set('postData', Immutable.fromJS(action.response.response))
        .set('blogTags', Immutable.fromJS(action.response.info.blogTags))

    case ActionType.GET_RECENT_POST_SUCCESS:
      return state.set(
        'recentPosts',
        Immutable.fromJS(action.response.response)
      )

    default:
      return state
  }
}
