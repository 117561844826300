let theme = {
  palette: {
    primary: {
      main: '#0b5d90', //500
      contrastText: '#fff',
    },
    secondary: {
      main: '#ef6e51',
    },
    ifoodie: {
      main: '#0b5d90',
      contrastText: '#fff',
    },
    orange: {
      main: '#e35032',
      contrastText: '#fff',
    },
    success: {
      main: '#29b630',
    },
    cancel: {
      main: '#666',
    },
    grey: {
      main: '#666',
    },
    lightgrey: {
      main: '#aaa',
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
  },
}

theme = {
  ...theme,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSuccess: {
          backgroundColor: '#81d53c',
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: '#134f6c',
        },
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
}

export default theme
