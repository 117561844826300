import Immutable, { fromJS } from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/blogger'

const initData = {
  data: null,
  offset: 0,
  hasMore: false,
}

export const initialState = fromJS({
  _hasInit: false,

  USER_POST_DRAFT: { ...initData },
  USER_POST_PUBLIC: { ...initData },
  USER_POST_REVIEWING: { ...initData },
  USER_POST_REJECTED: { ...initData },
  editorPostData: { isSyncing: false },
})

const REDUCER_NODE = 'blogger'

export default function blogger(state = initialState, action) {
  let postData, editorPostData
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_BLOGGER_SUCCESS:
      return state

    case ActionType.IMAGE_UPLOAD_PREPARE:
      postData = state.getIn(['editorPostData', 'postData']) || Immutable.List()
      if (action.index < 0 || action.index > postData.size - 1) {
        postData = postData.push({
          paraType: 1,
          seq: postData.size,
          src: '',
          aspectRatio: action.height / action.width,
          uploadTag: action.tag,
        })
      } else {
        postData = postData.splice(action.index, 0, {
          paraType: 1,
          seq: postData.size,
          src: '',
          aspectRatio: action.height / action.width,
          uploadTag: action.tag,
        })
      }
      return state
        .setIn(['editorPostData', 'postData'], postData)
        .setIn(['editorPostData', 'localVersionHash'], action.localVersionHash)

    case ActionType.IMAGE_UPLOAD_SUCCESS:
      editorPostData = state.get('editorPostData')
      if (!editorPostData) {
        return state
      }
      postData = editorPostData.get('postData')
        ? editorPostData.get('postData')
        : Immutable.List([])
      postData = postData.map((paragraph, index) => {
        if (
          paragraph.paraType == 1 &&
          paragraph.uploadTag &&
          paragraph.uploadTag == action.tag
        ) {
          return {
            paraType: 1,
            seq: paragraph.seq,
            src: action.url,
            aspectRatio: action.height / action.width,
            uploadTag: action.tag,
          }
        }
        return paragraph
      })
      return state.set(
        'editorPostData',
        editorPostData.merge(
          Immutable.Map({
            postData,
            imageToUpdate: [action.tag, action.url],
            progressToUpdate: (
              editorPostData.get('progressToUpdate') || new Immutable.Map()
            ).delete(action.tag),
          })
        )
      )

    case ActionType.IMAGE_UPLOAD_PROGRESS:
      editorPostData = state.get('editorPostData')
      if (!editorPostData) return state
      return state.setIn(
        ['editorPostData', 'progressToUpdate'],
        (editorPostData.get('progressToUpdate') || new Immutable.Map()).set(
          action.tag,
          action.percent
        )
      )

    case ActionType.EDITOR_SYNC:
      return state
        .setIn(['editorPostData', 'isSyncing'], true)
        .setIn(['editorPostData', 'localVersionHash'], action.versionHash)
    case ActionType.EDITOR_SYNC_SUCCESS:
      return state
        .setIn(['editorPostData', 'isSyncing'], false)
        .setIn(
          ['editorPostData', 'blog'],
          Immutable.Map(action.response.info.blog)
        )
        .setIn(['editorPostData', 'blogId'], action.response.response.blogId)
        .setIn(
          ['editorPostData', 'remoteVersionHash'],
          action.response.response.hashCode
        )
    case ActionType.EDITOR_SYNC_FAILED:
      return state.setIn(['editorPostData', 'isSyncing'], false)

    case ActionType.INIT_EDITOR_WITH_DATA:
      return state.set(
        'editorPostData',
        Immutable.Map({
          postData: new Immutable.List(action.postData),
          title: action.title,
          date: action.timestamp,
          refUrl: action.refUrl,
        })
      )

    case ActionType.SET_BLOG_TYPE_SUCCESS:
      return state
        .setIn(['editorPostData', 'blog', 'status'], action.blogType)
        .setIn(['editorPostData', 'blog', 'rating'], action.rating)
        .setIn(['editorPostData', 'blog', 'price'], action.price)

    case ActionType.EDITOR_UPDATE_LOCAL:
      return state
        .setIn(['editorPostData', 'title'], action.title)
        .setIn(['editorPostData', 'postData'], new Immutable.List(action.data))
        .setIn(['editorPostData', 'date'], action.date)
        .setIn(['editorPostData', 'localVersionHash'], action.editorVersionHash)

    case ActionType.GET_BLOG_SUCCESS:
      return state
        .setIn(['editorPostData', 'title'], action.response.response.title)
        .setIn(['editorPostData', 'date'], action.response.response.date)
        .setIn(['editorPostData', 'coverUrl'], action.response.response.thumb)
        .setIn(
          ['editorPostData', 'blog'],
          Immutable.Map(action.response.response)
        )
        .setIn(['editorPostData', 'refUrl'], action.response.response.refUrl)
        .setIn(
          ['editorPostData', 'restaurant'],
          action.response.response.restaurant
            ? Immutable.Map(action.response.response.restaurant)
            : null
        )

    case ActionType.SET_BLOG_COVER_SUCCESS:
      return state.setIn(['editorPostData', 'coverUrl'], action.coverUrl)

    case ActionType.GET_POST_SUCCESS:
      return state
        .setIn(
          ['editorPostData', 'remoteVersionHash'],
          action.response.response.hashCode
        )
        .setIn(['editorPostData', 'isSyncing'], false)
        .setIn(['editorPostData', 'postId'], action.response.response.id)
        .setIn(['editorPostData', 'blogId'], action.response.response.blogId)
        .setIn(['editorPostData', 'refUrl'], action.response.info.blog.refUrl)
        .setIn(
          ['editorPostData', 'postData'],
          new Immutable.List(action.response.response.content)
        )

    case ActionType.DELETE_BLOG_SUCCESS:
      let blogList = state.getIn([action.listType, 'data'])
      return state.setIn(
        [action.listType, 'data'],
        blogList.filter((o) => o.get('id') !== action.blogId)
      )

    case ActionType.RATING_BLOG_SUCCESS:
      let ratingBlogList = state.getIn([action.listType, 'data'])
      ratingBlogList = ratingBlogList.map((item, index) => {
        if (item.get('id') == action.blogId) {
          return item.set('rating', action.rating)
        }
        return item
      })
      return state.setIn([action.listType, 'data'], ratingBlogList)

    case ActionType.GET_BLOG_LIST_SUCCESS:
      const hasMore = action.response.response.length >= 20
      const offset =
        state.getIn([action.listType, 'offset']) +
        action.response.response.length
      const newState = state
        .setIn([action.listType, 'hasMore'], hasMore)
        .setIn([action.listType, 'offset'], offset)

      if (state.getIn([action.listType, 'data'])) {
        return newState.updateIn([action.listType, 'data'], (data) =>
          data.concat(fromJS(action.response.response)).toOrderedSet().toList()
        )
      } else {
        return newState.setIn(
          [action.listType, 'data'],
          fromJS(action.response.response)
        )
      }

    default:
      return state
  }
}
