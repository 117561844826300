import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('checkins')
import superagent from 'superagent'

export const GET_CHECKIN_INFO = createConst('GET_CHECKIN_INFO')
export const GET_CHECKIN_INFO_SUCCESS = createConst('GET_CHECKIN_INFO_SUCCESS')
export const GET_CHECKIN_INFO_FAILED = createConst('GET_CHECKIN_INFO_FAILED')
export function getCheckinInfo({ restaurantId, offset, limit }) {
  return {
    [CALL_API]: {
      path: '/checkin/',
      method: 'get',
      query: { restaurantId, offset, limit },
      sendingType: GET_CHECKIN_INFO,
      successType: GET_CHECKIN_INFO_SUCCESS,
      errorType: GET_CHECKIN_INFO_FAILED
    }
  }
}

export function getUserCheckinInfo({ userId, offset, limit }) {
  return {
    [CALL_API]: {
      path: '/checkin/',
      method: 'get',
      query: { userId, offset, limit },
      sendingType: GET_CHECKIN_INFO,
      successType: GET_CHECKIN_INFO_SUCCESS,
      errorType: GET_CHECKIN_INFO_FAILED
    }
  }
}

export const GET_CHECKIN_INFO_MORE = createConst('GET_CHECKIN_INFO_MORE')
export const GET_CHECKIN_INFO_MORE_SUCCESS = createConst('GET_CHECKIN_INFO_MORE_SUCCESS')
export const GET_CHECKIN_INFO_MORE_FAILED = createConst('GET_CHECKIN_INFO_MORE_FAILED')
export function getCheckinInfoMore({ restaurantId, offset, limit }) {
  return {
    [CALL_API]: {
      path: '/checkin/',
      method: 'get',
      query: { restaurantId, offset, limit },
      sendingType: GET_CHECKIN_INFO_MORE,
      successType: GET_CHECKIN_INFO_MORE_SUCCESS,
      errorType: GET_CHECKIN_INFO_MORE_FAILED
    },
    offset
  }
}

export function getUserCheckinInfoMore({ userId, offset, limit }) {
  return {
    [CALL_API]: {
      path: '/checkin/',
      method: 'get',
      query: { userId, offset, limit },
      sendingType: GET_CHECKIN_INFO_MORE,
      successType: GET_CHECKIN_INFO_MORE_SUCCESS,
      errorType: GET_CHECKIN_INFO_MORE_FAILED
    },
    offset
  }
}


export const ADD_CHECKIN = createConst('ADD_CHECKIN')
export const ADD_CHECKIN_SUCCESS = createConst('ADD_CHECKIN_SUCCESS')
export const ADD_CHECKIN_FAILED = createConst('ADD_CHECKIN_FAILED')
export function addCheckin({ restaurantId, message, photo, rating }) {
  return {
    [CALL_API]: {
      path: '/checkin/',
      method: 'post',
      body: { restaurantId, message, photo, rating },
      sendingType: ADD_CHECKIN,
      successType: ADD_CHECKIN_SUCCESS,
      errorType: ADD_CHECKIN_FAILED
    }
  }
}

export const UPDATE_UPLOAD_IMAGE_PROGRESS = createConst('UPDATE_UPLOAD_IMAGE_PROGRESS')
export function updateUploadImageProgress({ progress }) {
  return {
    type: UPDATE_UPLOAD_IMAGE_PROGRESS,
    progress
  }
}

export const UPLOAD_IMAGE = createConst('UPLOAD_IMAGE')
export const UPLOAD_IMAGE_SUCCESS = createConst('UPLOAD_IMAGE_SUCCESS')
export const UPLOAD_IMAGE_FAILED = createConst('UPLOAD_IMAGE_FAILED')
export function uploadImageSuccess({ imgUrl }) {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    imgUrl
  }
}

export const GET_UPLOAD_IMAGE_URL = createConst('GET_UPLOAD_IMAGE_URL')
export const GET_UPLOAD_IMAGE_URL_SUCCESS = createConst('GET_UPLOAD_IMAGE_URL_SUCCESS')
export const GET_UPLOAD_IMAGE_URL_FAILED = createConst('GET_UPLOAD_IMAGE_URL_FAILED')
export function uploadImage({ imgFile }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/globals/get_upload_image_url/',
      sendingType: GET_UPLOAD_IMAGE_URL,
      successType: GET_UPLOAD_IMAGE_URL_SUCCESS,
      errorType: GET_UPLOAD_IMAGE_URL_FAILED,
      afterSuccess: ({ dispatch, response }) => {
        let uploadUrl = response.response.url
        let data = new FormData()
        data.append('file', imgFile)
        superagent
          .post(uploadUrl)
          .send(data)
          .on('progress',(progress)=>{
            dispatch(updateUploadImageProgress({ progress: Math.round(progress.percent) }))
          }).end((err, res)=>{
            dispatch(uploadImageSuccess({imgUrl: `${res.body.url}=s600`}))
          })
      }
    }
  }
}

export const ADD_LIKE = createConst('ADD_LIKE')
export const ADD_LIKE_SUCCESS = createConst('ADD_LIKE_SUCCESS')
export const ADD_LIKE_FAILED = createConst('ADD_LIKE_FAILED')
export function addLike({ checkinId }) {
  return {
    checkinId,
    [CALL_API]: {
      path: `/checkin/${checkinId}/like/`,
      method: 'post',
      sendingType: ADD_LIKE,
      successType: ADD_LIKE_SUCCESS,
      errorType: ADD_LIKE_FAILED
    }
  }
}

export const REMOVE_LIKE = createConst('REMOVE_LIKE')
export const REMOVE_LIKE_SUCCESS = createConst('REMOVE_LIKE_SUCCESS')
export const REMOVE_LIKE_FAILED = createConst('REMOVE_LIKE_FAILED')
export function removeLike({ checkinId }) {
  return {
    checkinId,
    [CALL_API]: {
      path: `/checkin/${checkinId}/like/`,
      method: 'delete',
      sendingType: REMOVE_LIKE,
      successType: REMOVE_LIKE_SUCCESS,
      errorType: REMOVE_LIKE_FAILED
    }
  }
}

export const REPLY_CHECKIN = createConst('REPLY_CHECKIN')
export const REPLY_CHECKIN_SUCCESS = createConst('REPLY_CHECKIN_SUCCESS')
export const REPLY_CHECKIN_FAILED = createConst('REPLY_CHECKIN_FAILED')
export function replyCheckin({ checkinId, message }) {
  return {
    checkinId,
    message,
    [CALL_API]: {
      path: `/checkin/${checkinId}/reply/`,
      method: 'post',
      body: { message },
      sendingType: REPLY_CHECKIN,
      successType: REPLY_CHECKIN_SUCCESS,
      errorType: REPLY_CHECKIN_FAILED
    }
  }
}
