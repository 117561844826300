import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('post')

export const GET_POST = createConst('GET_POST')
export const GET_POST_SUCCESS = createConst('GET_POST_SUCCESS')
export const GET_POST_FAILED = createConst('GET_POST_FAILED')
export function getPost({ postId, lang }) {
  return {
    [CALL_API]: {
      path: `/blog/${postId}/post`,
      query: ( lang ? { lang } : {} ),
      method: 'get',
      sendingType: GET_POST,
      successType: GET_POST_SUCCESS,
      errorType: GET_POST_FAILED
    }
  }
}


export const GET_RECENT_POST = createConst('GET_RECENT_POST')
export const GET_RECENT_POST_SUCCESS = createConst('GET_RECENT_POST_SUCCESS')
export const GET_RECENT_POST_FAILED = createConst('GET_RECENT_POST_FAILED')
export function getRecentPost() {
  return {
    [CALL_API]: {
      path: '/community/recently/',
      method: 'get',
      query: {
        limit: 20,
        offset: 0,
        sampleCnt: 11
      },
      sendingType: GET_RECENT_POST,
      successType: GET_RECENT_POST_SUCCESS,
      errorType: GET_RECENT_POST_FAILED
    }
  }
}

