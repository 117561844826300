import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/geoLocation'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  currentPosition: {},
  errorStatus: '',
  updateSignal: false,
  isLoading: false,
})

const REDUCER_NODE = 'geoLocation'

export default function geoLocation(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_CURRENT_POSITION:
      return state.set('isLoading', true).set('errorStatus', '')

    case ActionType.GET_CURRENT_POSITION_SUCCESS:
      return state
        .set('isLoading', false)
        .update('updateSignal', (signal) => !signal)
        .setIn(['currentPosition', 'timestamp'], action.position.timestamp)
        .setIn(
          ['currentPosition', 'coords', 'latitude'],
          action.position.coords.latitude
        )
        .setIn(
          ['currentPosition', 'coords', 'longitude'],
          action.position.coords.longitude
        )
        .set('errorStatus', '')

    case ActionType.GET_CURRENT_POSITION_FAILED:
      return state.set('isLoading', false).update('errorStatus', () => {
        const errorStatusMap = {
          [action.error.PERMISSION_DENIED]: 'permission-denied',
          [action.error.POSITION_UNAVAILABLE]: 'unavaiable',
          [action.error.TIMEOUT]: 'timeout',
          [action.error.UNKNOWN_ERROR]: 'unknown',
          unknown: 'unknown',
        }
        return errorStatusMap[action.error.code]
      })
    default:
      return state
  }
}
