
import ABTesting from './ABTesting'

export default {
  config({ isMobile, randomNum }) {
    this.isMobile = isMobile
    this.randomNum = randomNum
    ABTesting.config({ isMobile, randomNum })
  },
  getIsMobile() {
    return this.isMobile
  },
  getRandomNum() {
    return this.randomNum
  }
}


