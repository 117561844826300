import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('app')
import { LOCAL_STORAGE } from '@/store/middlewares/localStorage'

export const SET_DOWNLOAD_BANNER_STATUS = createConst(
  'SET_DOWNLOAD_BANNER_STATUS'
)
export function setDownloadBannerStatus({ closeTimestamp }) {
  return {
    [LOCAL_STORAGE]: {
      successType: SET_DOWNLOAD_BANNER_STATUS,
      key: 'downloadBannerStatus',
      value: closeTimestamp,
      method: 'set',
    },
  }
}

export const GET_DOWNLOAD_BANNER_STATUS = createConst(
  'GET_DOWNLOAD_BANNER_STATUS'
)
export function getDownloadBannerStatus() {
  return {
    [LOCAL_STORAGE]: {
      successType: GET_DOWNLOAD_BANNER_STATUS,
      key: 'downloadBannerStatus',
      method: 'checkWithCallback',
      callback: () => {},
    },
  }
}

export const SET_MOBILE_STATUS = createConst('SET_MOBILE_STATUS')
export function setMobileStatus(mobileStatus) {
  return {
    type: SET_MOBILE_STATUS,
    mobileStatus,
  }
}

export const SET_RANDOM_NUM = createConst('SET_RANDOM_NUM')
export function setRandomNum(randomNum) {
  return {
    type: SET_RANDOM_NUM,
    randomNum,
  }
}

export const SET_LANG = createConst('SET_LANG')
export function setLang(lang) {
  return {
    type: SET_LANG,
    lang,
  }
}
