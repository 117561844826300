import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('auth')

export const SET_LOGIN_MODAL_OPEN_STATUS = createConst('OPEN_LOGIN_MODAL')
export function setLoginModalOpenStatus({ isOpen }) {
  return {
    type: SET_LOGIN_MODAL_OPEN_STATUS,
    isOpen,
  }
}
