import { combineReducers } from 'redux'

import currentUser from './currentUser'
import restaurants from './restaurants'
import auth from './auth'
import search from './search'
import home from './home'
import geoLocation from './geoLocation'
import post from './post'
import app from './app'
import dish from './dish'
import news from './news'
import checkins from './checkins'
import user from './user'
import cards from './cards'
import community from './community'
import business from './business'
import blogger from './blogger'

export default combineReducers({
  app,
  auth,
  blogger,
  business,
  cards,
  checkins,
  community,
  currentUser,
  dish,
  geoLocation,
  home,
  news,
  post,
  restaurants,
  search,
  user,
})
