import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/news'
const ITEMS_PER_PAGE = 20

export const initialState = Immutable.fromJS({
  _hasInit: false,

  blogList: {
    data: [],
    isLoading: false,
    isLoadingMore: false,
    offset: 0,
    city: '',
  },
  recentChartList: {
    firstCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
      city: '',
    },
    secondCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
      city: '',
    },
    thirdCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
      city: '',
    },
  },
})

const REDUCER_NODE = 'news'

export default function news(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_BLOG_LIST:
      return state
        .setIn(['blogList', 'isLoading'], true)
        .setIn(['blogList', 'city'], action.cityName)

    case ActionType.GET_BLOG_LIST_SUCCESS:
      return state
        .setIn(['blogList', 'isLoading'], false)
        .setIn(['blogList', 'data'], Immutable.fromJS(action.response.response))
        .setIn(['blogList', 'offset'], ITEMS_PER_PAGE)

    case ActionType.GET_BLOG_LIST_FAILED:
      return state.setIn(['blogList', 'isLoading'], false)

    case ActionType.GET_BLOG_LIST_MORE:
      return state.setIn(['blogList', 'isLoadingMore'], true)

    case ActionType.GET_BLOG_LIST_MORE_SUCCESS:
      return state
        .setIn(['blogList', 'isLoadingMore'], false)
        .setIn(
          ['blogList', 'offset'],
          state.getIn(['blogList', 'offset']) + ITEMS_PER_PAGE
        )
        .updateIn(['blogList', 'data'], (data) =>
          data
            .concat(Immutable.fromJS(action.response.response))
            .toOrderedSet()
            .toList()
        )

    case ActionType.GET_BLOG_LIST_MORE_FAILED:
      return state.setIn(['blogList', 'isLoadingMore'], false)

    case ActionType.GET_RECENT_CHART_LIST:
      return state
        .setIn(
          ['recentChartList', action.listType, 'data'],
          initialState.getIn(['recentChartList', action.listType, 'data'])
        )
        .setIn(
          ['recentChartList', action.listType, 'ads'],
          initialState.getIn(['recentChartList', action.listType, 'ads'])
        )
        .setIn(['recentChartList', action.listType, 'isLoading'], true)
        .setIn(['recentChartList', action.listType, 'keyword'], action.keyword)
        .setIn(['recentChartList', action.listType, 'city'], action.city)

    case ActionType.GET_RECENT_CHART_LIST_FAILED:
      return state
        .setIn(['recentChartList', action.listType, 'isLoading'], false)
        .setIn(['recentChartList', action.listType, 'keyword'], '')
        .setIn(['recentChartList', action.listType, 'city'], '')

    case ActionType.GET_RECENT_CHART_LIST_SUCCESS:
      return state
        .setIn(
          ['recentChartList', action.listType, 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(
          ['recentChartList', action.listType, 'ads'],
          Immutable.fromJS(
            !!action.response.info.ads ? action.response.info.ads : []
          )
        )
        .setIn(['recentChartList', action.listType, 'isLoading'], false)

    default:
      return state
  }
}
