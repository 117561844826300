import { window } from '@/libs/globalUtils'

export default {
  sendPixel: ({
    content_name,
    content_category,
    content_type,
    content_ids,
    fbEvent = 'ViewContent',
    extraData = {},
  }) => {
    if (!window) {
      return
    }
    const data = {
      content_name,
      content_category,
      content_ids,
      content_type,
    }
    const gtmFbPixel = {
      fbPixel: { ...data, ...extraData },
      fbPixelReady: true,
      fbEvent,
      event: 'fbPixelReadyEvent',
    }

    function waitForFbq(callback) {
      if (typeof window.fbq !== 'undefined') {
        callback()
      } else {
        setTimeout(function () {
          waitForFbq(callback)
        }, 100)
      }
    }

    waitForFbq(function () {
      window.dataLayer.push(gtmFbPixel)
    })

  },
  setUserData: ({ email, userId }) => {
    if (!window) {
      return
    }
    window.dataLayer.push({
      email,
      userId,
      event: 'fbPixelInitEvent',
    })
  },
}
