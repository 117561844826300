export default function detectBot(userAgent) {
  let isBot = false
  if (
    /Googlebot|Bot|crawler|grapeshot|Crawler|robot|bingbot|comscore|admantx|Mediapartner|thetradedesk|sirdata/.test(
      userAgent
    )
  ) {
    isBot = true
  }
  return { isBot }
}
