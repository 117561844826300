import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/cards'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  promote: {
    isLoading: false,
    data: [],
  },
  cardInfo: {},
})

const REDUCER_NODE = 'cards'

export default function cards(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_CARDS:
      return state.setIn([action.listType, 'isLoading'], true)

    case ActionType.GET_CARDS_SUCCESS:
      return state
        .setIn(
          [action.listType, 'data'],
          Immutable.fromJS(action.response.response)
            .groupBy((x) => x.get('id'))
            .map((x) => x.first())
            .toList()
        )
        .setIn([action.listType, 'isLoading'], false)

    case ActionType.GET_CARDS_FAILED:
      return state.setIn([action.listType, 'isLoading'], false)

    case ActionType.GET_CARD_INFO:
      return state.setIn(['cardInfo'], initialState.get('cardInfo'))

    case ActionType.GET_CARD_INFO_SUCCESS:
      return state.setIn(
        ['cardInfo'],
        Immutable.fromJS(action.response.response)
      )
    case ActionType.GET_CARD_INFO_FAILED:
      return state

    default:
      return state
  }
}
