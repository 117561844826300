import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/restaurants'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  campaign: {
    isLoading: false,
    data: [],
  },
  reservation: {
    isLoading: false,
    currentRestaurant: null,
    data: [],
    city: null,
  },
  restaurantInfo: {},
  parkingInfo: {},
  takeoutInfo: {},
  funnowInfo: {},
  fanPageInfo: {},
  photoList: {
    data: [],
  },
  recommendRestaurants: [],
  checkinInfo: {
    offset: 0,
    data: [],
    isFinish: false,
  },
  blogList: {
    offset: 0,
    data: [],
    isFinish: false,
  },
  ifoodieCard: [],
  menuList: [],
  recentPosts: [],
  allCategoryData: {},
  userSuggestCategory: {
    isLoading: false,
  },
  recentChartList: {
    firstCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
    },
    randomCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
    },
    randomCategoryInPost: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
    },
  },
  reportErrorMessage: {
    isLoading: false,
    isSuccess: false,
    isFailed: false,
  },
})

const REDUCER_NODE = 'restaurants'

export default function restaurants(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_RESTAURANT_DETAIL:
      return state
        .merge({
          restaurantInfo: initialState.get('restaurantInfo'),
          parkingInfo: initialState.get('parkingInfo'),
          funnowInfo: initialState.get('funnowInfo'),
          fanPageInfo: initialState.get('fanPageInfo'),
          recommendRestaurants: initialState.get('recommendRestaurants'),
          checkinInfo: initialState.get('checkinInfo'),
          blogList: initialState.get('blogList'),
          allCategoryData: initialState.get('allCategoryData'),
          userSuggestCategory: initialState.get('userSuggestCategory'),
        })
        .setIn(
          ['recentChartList', 'firstCategory'],
          initialState.getIn(['recentChartList', 'firstCategory'])
        )
        .setIn(
          ['recentChartList', 'randomCategory'],
          initialState.getIn(['recentChartList', 'randomCategory'])
        )

    case ActionType.GET_CAMPAIGN_RESTAURANTS:
      return state.setIn(['campaign', 'isLoading'], true)

    case ActionType.GET_CAMPAIGN_RESTAURANTS_SUCCESS:
      return state
        .setIn(
          ['campaign', 'data'],
          Immutable.fromJS(action.response.response)
            .groupBy((x) => x.get('id'))
            .map((x) => x.first())
            .toList()
        )
        .setIn(['campaign', 'isLoading'], false)

    case ActionType.GET_CAMPAIGN_RESTAURANTS_FAILED:
      return state.setIn(['campaign', 'isLoading'], false)

    case ActionType.GET_RESERVABLE_RESTAURANTS:
      return state.setIn(['reservation', 'isLoading'], true)

    case ActionType.GET_RESERVABLE_RESTAURANTS_SUCCESS:
      return state
        .setIn(
          ['reservation', 'data'],
          Immutable.fromJS(action.response.response)
            .groupBy((x) => x.get('id'))
            .map((x) => x.first())
            .toList()
        )
        .setIn(['reservation', 'isLoading'], false)
        .setIn(['reservation', 'city'], action.city)

    case ActionType.GET_RESERVABLE_RESTAURANTS_FAILED:
      return state.setIn(['reservation', 'isLoading'], false)

    case ActionType.GET_RESERVE_RESTAURANT_SUCCESS:
      return state.setIn(
        ['reservation', 'currentRestaurant'],
        Immutable.fromJS(action.response.response)
      )

    case ActionType.UPDATE_CURRENT_RESERVE_RESTAURANT:
      return state.setIn(
        ['reservation', 'currentRestaurant'],
        Immutable.fromJS(action.restaurant)
      )

    case ActionType.GET_RESTAURANT_INFO:
      // loading
      return state

    case ActionType.GET_RESTAURANT_INFO_SUCCESS:
      return state.setIn(
        ['restaurantInfo'],
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_PARKING_INFO:
      return state.setIn(['parkingInfo'], initialState.get('parkingInfo'))

    case ActionType.GET_PARKING_INFO_SUCCESS:
      return state.setIn(
        ['parkingInfo'],
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_TAKEOUT_INFO:
      return state.setIn(['takeoutInfo'], initialState.get('takeoutInfo'))

    case ActionType.GET_TAKEOUT_INFO_SUCCESS:
      return state.setIn(
        ['takeoutInfo'],
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_FUNNOW_INFO:
      return state.setIn(['funnowInfo'], initialState.get('funnowInfo'))

    case ActionType.GET_FUNNOW_INFO_SUCCESS:
      return state.setIn(
        ['funnowInfo'],
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_FANPAGE_INFO:
      return state.setIn(['fanPageInfo'], initialState.get('fanPageInfo'))

    case ActionType.GET_FANPAGE_INFO_SUCCESS:
      return state
        .setIn(['fanPageInfo'], Immutable.fromJS(action.response.info))
        .setIn(
          ['photoList', 'data'],
          Immutable.fromJS(action.response.response)
        )

    case ActionType.GET_MENU_SUCCESS:
      return state.set('menuList', Immutable.fromJS(action.response.response))

    case ActionType.GET_RECOMMEND_RESTAURANTS_SUCCESS:
      return state.set(
        'recommendRestaurants',
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_BLOG_LIST_SUCCESS:
      return state
        .set('blogList', initialState.get('blogList'))
        .setIn(['blogList', 'data'], Immutable.fromJS(action.response.response))
        .setIn(['blogList', 'isFinish'], action.response.response.length === 0)

    case ActionType.GET_BLOG_LIST_MORE_SUCCESS:
      return state
        .updateIn(['blogList', 'data'], (list) => {
          return list.concat(Immutable.fromJS(action.response.response))
        })
        .setIn(['blogList', 'offset'], action.offset)
        .setIn(['blogList', 'isFinish'], action.response.response.length === 0)

    case ActionType.GET_IFOODIE_CARD:
      return state.set('ifoodieCard', initialState.get('ifoodieCard'))

    case ActionType.GET_IFOODIE_CARD_SUCCESS:
      return state.set(
        'ifoodieCard',
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_ALL_CATEGORY:
      return state.setIn(
        ['allCategoryData'],
        initialState.get('allCategoryData')
      )

    case ActionType.GET_ALL_CATEGORY_SUCCESS:
      return state.setIn(
        ['allCategoryData'],
        Immutable.fromJS(action.response.response)
      )

    case ActionType.SEND_USER_SUGGEST_CATEGORY:
      return state.setIn(['userSuggestCategory', 'isLoading'], true)

    case ActionType.SEND_USER_SUGGEST_CATEGORY_SUCCESS:
      return state.setIn(['userSuggestCategory', 'isLoading'], false)

    case ActionType.GET_RECENT_CHART_LIST:
      return state
        .setIn(
          ['recentChartList', action.listType, 'data'],
          initialState.getIn(['recentChartList', action.listType, 'data'])
        )
        .setIn(
          ['recentChartList', action.listType, 'ads'],
          initialState.getIn(['recentChartList', action.listType, 'ads'])
        )
        .setIn(['recentChartList', action.listType, 'isLoading'], true)
        .setIn(['recentChartList', action.listType, 'keyword'], action.keyword)

    case ActionType.GET_RECENT_CHART_LIST_FAILED:
      return state
        .setIn(['recentChartList', action.listType, 'isLoading'], false)
        .setIn(['recentChartList', action.listType, 'keyword'], '')

    case ActionType.GET_RECENT_CHART_LIST_SUCCESS:
      return state
        .setIn(
          ['recentChartList', action.listType, 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(
          ['recentChartList', action.listType, 'ads'],
          Immutable.fromJS(
            !!action.response.info.ads ? action.response.info.ads : []
          )
        )
        .setIn(['recentChartList', action.listType, 'isLoading'], false)

    case ActionType.REPORT_RESTAURANT:
      return state
        .setIn(['reportErrorMessage', 'isLoading'], true)
        .setIn(['reportErrorMessage', 'isSuccess'], false)
        .setIn(['reportErrorMessage', 'isFailed'], false)

    case ActionType.REPORT_RESTAURANT_SUCCESS:
      return state
        .setIn(['reportErrorMessage', 'isLoading'], false)
        .setIn(['reportErrorMessage', 'isSuccess'], true)
        .setIn(['reportErrorMessage', 'isFailed'], false)

    case ActionType.REPORT_RESTAURANT_FAILED:
      return state
        .setIn(['reportErrorMessage', 'isLoading'], false)
        .setIn(['reportErrorMessage', 'isSuccess'], false)
        .setIn(['reportErrorMessage', 'isFailed'], true)

    case ActionType.GET_RECENT_POST_SUCCESS:
      return state.set(
        'recentPosts',
        Immutable.fromJS(action.response.response)
      )

    default:
      return state
  }
}
