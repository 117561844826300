import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('home')

export const GET_NEW_BLOGS = createConst('GET_NEW_BLOGS')
export const GET_NEW_BLOGS_SUCCESS = createConst('GET_NEW_BLOGS_SUCCESS')
export const GET_NEW_BLOGS_FAILED = createConst('GET_NEW_BLOGS_FAILED')
export function getNewBlogs() {
  return {
    [CALL_API]: {
      path: '/blog/',
      method: 'get',
      query: {
        order_by: '-date',
        blogType: 201
      },
      sendingType: GET_NEW_BLOGS,
      successType: GET_NEW_BLOGS_SUCCESS,
      errorType: GET_NEW_BLOGS_FAILED
    }
  }
}
