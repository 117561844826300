import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/community'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  likeMap: {},
})

const REDUCER_NODE = 'community'

export default function community(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.UPDATE_LIKE_MAP:
      return action.articles
        ? state.mergeIn(
            ['likeMap'],
            action.articles
              .filter((article) => Boolean(article.isLike))
              .reduce((reduction, article) => {
                reduction[article.postId.toString()] = true
                return reduction
              }, {})
          )
        : state.mergeIn(
            ['likeMap'],
            action.comments
              .filter((comment) => Boolean(comment.isLike))
              .reduce((reduction, comment) => {
                reduction[comment.id] = true
                return reduction
              }, {})
          )

    case ActionType.UPDATE_LIKE_MAP_SINGLE:
      return state.setIn(
        ['likeMap', action.articleId.toString()],
        action.isLike
      )

    case ActionType.LIKE_REPLY:
    case ActionType.LIKE_ARTICLE:
      return state.setIn(
        ['likeMap', action.replyId || action.articleId.toString()],
        true
      )

    case ActionType.LIKE_REPLY_FAILED:
    case ActionType.LIKE_ARTICLE_FAILED:
      return state.setIn(
        ['likeMap', action.replyId || action.articleId.toString()],
        false
      )

    case ActionType.UNLIKE_REPLY:
    case ActionType.UNLIKE_ARTICLE:
      return state.setIn(
        ['likeMap', action.replyId || action.articleId.toString()],
        false
      )

    case ActionType.UNLIKE_REPLY_FAILED:
    case ActionType.UNLIKE_ARTICLE_FAILED:
      return state.setIn(
        ['likeMap', action.replyId || action.articleId.toString()],
        true
      )

    default:
      return state
  }
}
