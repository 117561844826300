import { initializeApp } from 'firebase/app'
// import { getAnalytics } from 'firebase/analytics'
// import { getRemoteConfig } from 'firebase/remote-config'

const firebaseConfig = {
  apiKey: 'AIzaSyCE9dy-K3gjnpjqL79Isqn2enCerGpGEYY',
  authDomain: 'ifoodie-website.firebaseapp.com',
  databaseURL: 'https://ifoodie-website.firebaseio.com',
  projectId: 'ifoodie-website',
  storageBucket: 'ifoodie-website.appspot.com',
  messagingSenderId: '856345193020',
  appId: '1:856345193020:web:80b940aaeb7fd401547139',
  measurementId: 'G-Z4SD5M21MG'
}

let app = null
let analytics = null

const initFirebase = () => {
  if (!app) {
    app = initializeApp(firebaseConfig)
  }
  return app
}

// if (typeof window !== 'undefined') {
//   app = initFirebase()
//   analytics = getAnalytics()
// }

export default { initFirebase, analytics }
