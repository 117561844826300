import ReactGA from 'react-ga4'

export default {
  generateImpressionProps: ({ action, category, label }) => ({
    'data-impression-event-type': 'ga-event',
    'data-ga-category': category,
    'data-ga-action': action,
    'data-ga-label': label,
  }),
  generateEventProps: ({ action, category, label }) => ({
    'data-click-event-type': 'ga-event',
    'data-ga-category': category,
    'data-ga-action': action,
    'data-ga-label': label,
  }),
  sendEvent: ({ action, category, label }) => {
    setTimeout(() => {
      ReactGA.event({ action, category, label })
    }, 0)
  },
  sendEventWithParams: ({ event, params }) => {
    setTimeout(() => {
      ReactGA.gtag("event", event, params);
    }, 0)
  },
  setUserProps: (userProps) => {
    setTimeout(() => {
      ReactGA.set({user_properties: userProps})
      // ReactGA.gtag("set", "user_properties", userProps);
    }, 0)
  },
}
