import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('home')


export const GET_WEEKLY_HOT_RESTAURANTS = createConst('GET_WEEKLY_HOT_RESTAURANTS')
export const GET_WEEKLY_HOT_RESTAURANTS_SUCCESS = createConst('GET_WEEKLY_HOT_RESTAURANTS_SUCCESS')
export const GET_WEEKLY_HOT_RESTAURANTS_FAILED = createConst('GET_WEEKLY_HOT_RESTAURANTS_FAILED')
export function getWeeklyHotRestaurants() {
  return {
    [CALL_API]: {
      path: '/restaurant/weekly_hot/',
      method: 'get',
      sendingType: GET_WEEKLY_HOT_RESTAURANTS,
      successType: GET_WEEKLY_HOT_RESTAURANTS_SUCCESS,
      errorType: GET_WEEKLY_HOT_RESTAURANTS_FAILED
    }
  }
}

export const GET_RECENT_USERCHECKIN = createConst('GET_RECENT_USERCHECKIN')
export const GET_RECENT_USERCHECKIN_SUCCESS = createConst('GET_RECENT_USERCHECKIN_SUCCESS')
export const GET_RECENT_USERCHECKIN_FAILED = createConst('GET_RECENT_USERCHECKIN_FAILED')
export function getRecentUserCheckin() {
  return {
    [CALL_API]: {
      path: '/checkin/recently/',
      method: 'get',
      sendingType: GET_RECENT_USERCHECKIN,
      successType: GET_RECENT_USERCHECKIN_SUCCESS,
      errorType: GET_RECENT_USERCHECKIN_FAILED
    }
  }
}

export const GET_CATEGORY_BLOCK_LIST = createConst('GET_CATEGORY_BLOCK_LIST')
export const GET_CATEGORY_BLOCK_LIST_SUCCESS = createConst('GET_CATEGORY_BLOCK_LIST_SUCCESS')
export const GET_CATEGORY_BLOCK_LIST_FAILED = createConst('GET_CATEGORY_BLOCK_LIST_FAILED')
export function getCategoryBlockList() {
  return {
    [CALL_API]: {
      path: '/globals/blocks/',
      method: 'get',
      sendingType: GET_CATEGORY_BLOCK_LIST,
      successType: GET_CATEGORY_BLOCK_LIST_SUCCESS,
      errorType: GET_CATEGORY_BLOCK_LIST_FAILED
    }
  }
}


export const GET_RECENT_POST = createConst('GET_RECENT_POST')
export const GET_RECENT_POST_SUCCESS = createConst('GET_RECENT_POST_SUCCESS')
export const GET_RECENT_POST_FAILED = createConst('GET_RECENT_POST_FAILED')
export function getRecentPost() {
  return {
    [CALL_API]: {
      path: '/community/',
      method: 'get',
      query: {
        limit: 12,
        offset: 0,
        type: 'ifoodie',
        orderBy: 'created'
      },
      sendingType: GET_RECENT_POST,
      successType: GET_RECENT_POST_SUCCESS,
      errorType: GET_RECENT_POST_FAILED
    }
  }
}

