import axios from 'axios'
import config from '@/config'
import geolib from 'geolib'
import { window } from '@/libs/globalUtils'


const TW_CENTER = {latitude: 23.800652, longitude: 121.003266}
const TW_RADIUS = 300000
export function findCityByName(cityName) {
  return new Promise(async resolve => {
    try {
      const { data } = await axios.get(`${config.coreApiEndpoint}/location/search`, {
        params: { q: cityName }
      })
      if(data.response.length > 0) {
        resolve(data.response[0])
      } else {
        resolve(null)
      }
    } catch(error) {
      resolve(null)
    }
  })
}

export function placeAutocomplete(input) {
  return (window && mapkit) ? (
    new Promise(async resolve => {
      const search = new mapkit.Search ({
        coordinate: new mapkit.Coordinate(25.045288, 121.518555),
        language: 'zh-Hant',
        getsUserLocation: false,
      })
      search.autocomplete(input, (err, data) => {
        if(data && data.results) {
          const results = data.results.filter(
            p => !!p.coordinate && geolib.isPointInCircle(
              p.coordinate,
              TW_CENTER,
              TW_RADIUS
            )
          )
          resolve(results)
        } else {
          resolve([])
        }
      })
    })
  ) : (
    new Promise(resolve => {
      resolve([])
    })
  )
}

export function findPlaceByRestaurant(restaurantId) {
  return new Promise(async resolve => {
    try {
      const { data } = await axios.get(`${config.coreApiEndpoint}/restaurant/${restaurantId}/`)
      if(data && data.success) {
        resolve(data.response)
      } else {
        resolve(null)
      }
    } catch(err) {
      resolve(null)
    }
  })
}

export function findPlaceByUser(mapUserId) {
  return new Promise(async resolve => {
    try {
      const { data } = await axios.get(`${config.coreApiEndpoint}/user/${mapUserId}/`)
      if(data && data.success) {
        resolve(data.response)
      } else {
        resolve(null)
      }
    } catch(err) {
      resolve(null)
    }
  })
}

export const MIN_SUGGESTIONS_SEARCH_DISTANCE = 2000
export function findPlaceByName(query) {
  return new Promise(async resolve => {
    const predictions = await placeAutocomplete(query)
    if(predictions.length > 0) {
      const prediction = predictions[0]
      const center = prediction.coordinate
      const sw = geolib.computeDestinationPoint(center, MIN_SUGGESTIONS_SEARCH_DISTANCE / 2, 225)
      const ne = geolib.computeDestinationPoint(center, MIN_SUGGESTIONS_SEARCH_DISTANCE / 2, 45)
      resolve({
        sw: `${sw.latitude},${sw.longitude}`,
        ne: `${ne.latitude},${ne.longitude}`,
        latlng: `${center.latitude},${center.longitude}`,
        place: query
      })
    } else {
      resolve(null)
    }
  })
}

export function getMinDistanceBound({ sw, ne }, minDistance) {
  const distance = geolib.getDistanceSimple(
    {latitude: sw.lat, longitude: sw.lng},
    {latitude: ne.lat, longitude: ne.lng}
  )

  if(distance > minDistance) {
    return { sw, ne }
  } else {
    const center = geolib.getCenter([
      {latitude: sw.lat, longitude: sw.lng},
      {latitude: ne.lat, longitude: ne.lng}
    ])
    const newSw = geolib.computeDestinationPoint(center, minDistance / 2, 225)
    const newNe = geolib.computeDestinationPoint(center, minDistance / 2, 45)

    return {
      sw: {
        lat: newSw.latitude,
        lng: newSw.longitude
      },
      ne: {
        lat: newNe.latitude,
        lng: newNe.longitude
      }
    }
  }
}