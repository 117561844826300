import localStorage from 'store'

export const LOCAL_STORAGE = Symbol('FETCH_LOCAL')
export const NAMESPACE = 'ifoodie-web'

let middleware = ({ dispatch }) => next => action => {
  let localInstruction = action[LOCAL_STORAGE]
  if (!localInstruction) {
    return next(action)
  }

  let { key, value, method, successType, callback } = localInstruction
  let storedKey = `${NAMESPACE}_${key}`

  if (method === 'set') {
    localStorage.set(storedKey, value)
  }

  if (method === 'remove') {
    localStorage.remove(storedKey)
  }

  let localData = localStorage.get(storedKey)
  let payload = actionWith({
    type: successType,
    localData,
  })

  if ( method === 'checkWithCallback') {
    let result = checkWithCallback({
      data: localData,
      callback
    })
    payload.result = result
  }
  dispatch(payload)

  function actionWith (merge) {
    let clonedAction = Object.assign({}, action)
    delete clonedAction[LOCAL_STORAGE]
    return Object.assign({}, clonedAction, merge)
  }

  function checkWithCallback ({ data, callback }) {
    if(!callback || typeof callback !== 'function') {
      throw Error('need a `callback` function for `LOCAL_STORAGE` checkWithCallback')
    }
    return callback(data)
  }

}

export default middleware
