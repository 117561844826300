import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('restaurants')
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'


export const GET_CAMPAIGN_RESTAURANTS = createConst('GET_CAMPAIGN_RESTAURANTS')
export const GET_CAMPAIGN_RESTAURANTS_SUCCESS = createConst(
  'GET_CAMPAIGN_RESTAURANTS_SUCCESS'
)
export const GET_CAMPAIGN_RESTAURANTS_FAILED = createConst(
  'GET_CAMPAIGN_RESTAURANTS_FAILED'
)
export function getCampaignRestaurants({ limit, offset }) {
  return {
    listType: 'campaign',
    [CALL_API]: {
      path: '/partner/hakka/',
      method: 'get',
      sendingType: GET_CAMPAIGN_RESTAURANTS,
      successType: GET_CAMPAIGN_RESTAURANTS_SUCCESS,
      errorType: GET_CAMPAIGN_RESTAURANTS_FAILED,
    },
  }
}

export const GET_RESERVABLE_RESTAURANTS = createConst('GET_RESERVABLE_RESTAURANTS')
export const GET_RESERVABLE_RESTAURANTS_SUCCESS = createConst('GET_RESERVABLE_RESTAURANTS_SUCCESS')
export const GET_RESERVABLE_RESTAURANTS_FAILED = createConst('GET_RESERVABLE_RESTAURANTS_FAILED')
export function getReserveRestaurants({ city, limit, offset }) {
  return {
    listType: 'reservation',
    city,
    [CALL_API]: {
      path: '/restaurant/reservation',
      method: 'get',
      query: { city, limit, offset, v: '2' },
      sendingType: GET_RESERVABLE_RESTAURANTS,
      successType: GET_RESERVABLE_RESTAURANTS_SUCCESS,
      errorType: GET_RESERVABLE_RESTAURANTS_FAILED,
    },
  }
}

export const GET_RESERVE_RESTAURANT = createConst('GET_RESERVE_RESTAURANT')
export const GET_RESERVE_RESTAURANT_SUCCESS = createConst('GET_RESERVE_RESTAURANT_SUCCESS')
export const GET_RESERVE_RESTAURANT_FAILED = createConst('GET_RESERVE_RESTAURANT_FAILED')
export function getSingleReserveRestaurant({ restaurantId }) {
  return {
    listType: 'reservation',
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/`,
      method: 'get',
      sendingType: GET_RESERVE_RESTAURANT,
      successType: GET_RESERVE_RESTAURANT_SUCCESS,
      errorType: GET_RESERVE_RESTAURANT_FAILED
    }
  }
}

export const UPDATE_CURRENT_RESERVE_RESTAURANT = createConst('UPDATE_CURRENT_RESERVE_RESTAURANT')
export function updateCurrentReserveRestaurant({ restaurant }) {
  return {
    type: UPDATE_CURRENT_RESERVE_RESTAURANT,
    restaurant
  }
}

export const GET_RESTAURANT_INFO = createConst('GET_RESTAURANT_INFO')
export const GET_RESTAURANT_INFO_SUCCESS = createConst('GET_RESTAURANT_INFO_SUCCESS')
export const GET_RESTAURANT_INFO_FAILED = createConst('GET_RESTAURANT_INFO_FAILED')
export function getRestaurantInfo({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/`,
      method: 'get',
      query: { web_page: true },
      sendingType: GET_RESTAURANT_INFO,
      successType: GET_RESTAURANT_INFO_SUCCESS,
      errorType: GET_RESTAURANT_INFO_FAILED
    }
  }
}

export const GET_RESTAURANT_DETAIL = createConst('GET_RESTAURANT_DETAIL')
export function clearRestaurantDetail() {
  return {
    type: GET_RESTAURANT_DETAIL
  }
}

export const GET_PARKING_INFO = createConst('GET_PARKING_INFO')
export const GET_PARKING_INFO_SUCCESS = createConst('GET_PARKING_INFO_SUCCESS')
export const GET_PARKING_INFO_FAILED = createConst('GET_PARKING_INFO_FAILED')
export function getParkingInfo({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/parking/`,
      method: 'get',
      sendingType: GET_PARKING_INFO,
      successType: GET_PARKING_INFO_SUCCESS,
      errorType: GET_PARKING_INFO_FAILED
    }
  }
}


export const GET_TAKEOUT_INFO = createConst('GET_TAKEOUT_INFO')
export const GET_TAKEOUT_INFO_SUCCESS = createConst('GET_TAKEOUT_INFO_SUCCESS')
export const GET_TAKEOUT_INFO_FAILED = createConst('GET_TAKEOUT_INFO_FAILED')
export function getTakeoutInfo({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/takeout/`,
      method: 'get',
      sendingType: GET_TAKEOUT_INFO,
      successType: GET_TAKEOUT_INFO_SUCCESS,
      errorType: GET_TAKEOUT_INFO_FAILED
    }
  }
}


export const GET_FUNNOW_INFO = createConst('GET_FUNNOW_INFO')
export const GET_FUNNOW_INFO_SUCCESS = createConst('GET_FUNNOW_INFO_SUCCESS')
export const GET_FUNNOW_INFO_FAILED = createConst('GET_FUNNOW_INFO_FAILED')
export function getFunnowInfo({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/funnow/`,
      method: 'get',
      sendingType: GET_FUNNOW_INFO,
      successType: GET_FUNNOW_INFO_SUCCESS,
      errorType: GET_FUNNOW_INFO_FAILED
    }
  }
}


export const GET_FANPAGE_INFO = createConst('GET_FANPAGE_INFO')
export const GET_FANPAGE_INFO_SUCCESS = createConst('GET_FANPAGE_INFO_SUCCESS')
export const GET_FANPAGE_INFO_FAILED = createConst('GET_FANPAGE_INFO_FAILED')
export function getFanPageInfo({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/photos/`,
      method: 'get',
      query: { limit: 24 },
      sendingType: GET_FANPAGE_INFO,
      successType: GET_FANPAGE_INFO_SUCCESS,
      errorType: GET_FANPAGE_INFO_FAILED
    }
  }
}

export const GET_RECOMMEND_RESTAURANTS = createConst('GET_RECOMMEND_RESTAURANTS')
export const GET_RECOMMEND_RESTAURANTS_SUCCESS = createConst('GET_RECOMMEND_RESTAURANTS_SUCCESS')
export const GET_RECOMMEND_RESTAURANTS_FAILED = createConst('GET_RECOMMEND_RESTAURANTS_FAILED')
export function getRecommendRestaurants({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/recommend/`,
      method: 'get',
      sendingType: GET_RECOMMEND_RESTAURANTS,
      successType: GET_RECOMMEND_RESTAURANTS_SUCCESS,
      errorType: GET_RECOMMEND_RESTAURANTS_FAILED
    }
  }
}

export const GET_BLOG_LIST = createConst('GET_BLOG_LIST')
export const GET_BLOG_LIST_SUCCESS = createConst('GET_BLOG_LIST_SUCCESS')
export const GET_BLOG_LIST_FAILED = createConst('GET_BLOG_LIST_FAILED')
export function getBlogList({ restaurantId, offset, limit }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/blogs/`,
      method: 'get',
      query: { offset, limit },
      sendingType: GET_BLOG_LIST,
      successType: GET_BLOG_LIST_SUCCESS,
      errorType: GET_BLOG_LIST_FAILED
    }
  }
}

export const GET_BLOG_LIST_MORE = createConst('GET_BLOG_LIST_MORE')
export const GET_BLOG_LIST_MORE_SUCCESS = createConst('GET_BLOG_LIST_MORE_SUCCESS')
export const GET_BLOG_LIST_MORE_FAILED = createConst('GET_BLOG_LIST_MORE_FAILED')
export function getBlogListMore({ restaurantId, offset, limit }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/blogs/`,
      method: 'get',
      query: { offset, limit },
      sendingType: GET_BLOG_LIST_MORE,
      successType: GET_BLOG_LIST_MORE_SUCCESS,
      errorType: GET_BLOG_LIST_MORE_FAILED
    },
    offset
  }
}

export const GET_IFOODIE_CARD = createConst('GET_IFOODIE_CARD')
export const GET_IFOODIE_CARD_SUCCESS = createConst('GET_IFOODIE_CARD_SUCCESS')
export const GET_IFOODIE_CARD_FAILED = createConst('GET_IFOODIE_CARD_FAILED')
export function getIfoodieCard({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/cards`,
      method: 'get',
      sendingType: GET_IFOODIE_CARD,
      successType: GET_IFOODIE_CARD_SUCCESS,
      errorType: GET_IFOODIE_CARD_FAILED
    }
  }
}

export const GET_MENU = createConst('GET_MENU')
export const GET_MENU_SUCCESS = createConst('GET_MENU_SUCCESS')
export const GET_MENU_FAILED = createConst('GET_MENU_FAILED')
export function getMenuList({ restaurantId }) {
  const limit = restaurantId == '619352c5781cc0611bc7f230' ? 30 : 20
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/menu`,
      query: { tok: 'ifoodie', limit },
      method: 'get',
      sendingType: GET_MENU,
      successType: GET_MENU_SUCCESS,
      errorType: GET_MENU_FAILED,
    },
  }
}

export const GET_ALL_CATEGORY = createConst('GET_ALL_CATEGORY')
export const GET_ALL_CATEGORY_SUCCESS = createConst('GET_ALL_CATEGORY_SUCCESS')
export const GET_ALL_CATEGORY_FAILED = createConst('GET_ALL_CATEGORY_FAILED')
export function getAllCategory() {
  return {
    [CALL_API]: {
      path: '/category/list/',
      method: 'get',
      sendingType: GET_ALL_CATEGORY,
      successType: GET_ALL_CATEGORY_SUCCESS,
      errorType: GET_ALL_CATEGORY_FAILED
    }
  }
}

export const SAVE_RESTAURANT = createConst('SAVE_RESTAURANT')
export const SAVE_RESTAURANT_SUCCESS = createConst('SAVE_RESTAURANT_SUCCESS')
export const SAVE_RESTAURANT_FAILED = createConst('SAVE_RESTAURANT_FAILED')
export function saveRestaurant({ restaurantId }) {
  return {
    [CALL_API]: {
      path: '/saved_place/',
      method: 'post',
      body: { restaurantId, isWeb: true },
      sendingType: SAVE_RESTAURANT,
      successType: SAVE_RESTAURANT_SUCCESS,
      errorType: SAVE_RESTAURANT_FAILED,
    },
  }
}

export const REMOVE_SAVED_RESTAURANT = createConst('REMOVE_SAVED_RESTAURANT')
export const REMOVE_SAVED_RESTAURANT_SUCCESS = createConst(
  'REMOVE_SAVED_RESTAURANT_SUCCESS'
)
export const REMOVE_SAVED_RESTAURANT_FAILED = createConst(
  'REMOVE_SAVED_RESTAURANT_FAILED'
)
export function removeSavedRestaurant({ restaurantId }) {
  return {
    [CALL_API]: {
      path: '/saved_place/',
      method: 'delete',
      query: { restaurantId },
      sendingType: REMOVE_SAVED_RESTAURANT,
      successType: REMOVE_SAVED_RESTAURANT_SUCCESS,
      errorType: REMOVE_SAVED_RESTAURANT_FAILED,
    },
  }
}

export const SEND_USER_SUGGEST_CATEGORY = createConst('SEND_USER_SUGGEST_CATEGORY')
export const SEND_USER_SUGGEST_CATEGORY_SUCCESS = createConst('SEND_USER_SUGGEST_CATEGORY_SUCCESS')
export const SEND_USER_SUGGEST_CATEGORY_FAILED = createConst('SEND_USER_SUGGEST_CATEGORY_FAILED')
export function sendUserSuggestCategory({ restaurantId, categories }) {
  return {
    [CALL_API]: {
      path: '/category/user_suggest',
      method: 'post',
      body: { restaurantId, categories },
      sendingType: SEND_USER_SUGGEST_CATEGORY,
      successType: SEND_USER_SUGGEST_CATEGORY_SUCCESS,
      errorType: SEND_USER_SUGGEST_CATEGORY_FAILED
    }
  }
}

export const GET_RECENT_CHART_LIST = createConst('GET_RECENT_CHART_LIST')
export const GET_RECENT_CHART_LIST_SUCCESS = createConst('GET_RECENT_CHART_LIST_SUCCESS')
export const GET_RECENT_CHART_LIST_FAILED = createConst('GET_RECENT_CHART_LIST_FAILED')
export function getRecentChartList({listType, keyword, city, adminName, limit = 10}) {
  return {
    listType,
    keyword,
    [CALL_API]: {
      path: '/restaurant/explore',
      method: 'get',
      query: omitBy({
        q: keyword,
        city_name: city,
        area_name: adminName,
        force_cache: true,
        order_by: 'popular',
        limit
      }, isNil),
      sendingType: GET_RECENT_CHART_LIST,
      successType: GET_RECENT_CHART_LIST_SUCCESS,
      errorType: GET_RECENT_CHART_LIST_FAILED
    }
  }
}

export const TRACK_AD_CLICK = createConst('TRACK_AD_CLICK')
export const TRACK_AD_CLICK_SUCCESS = createConst('TRACK_AD_CLICK_SUCCESS')
export const TRACK_AD_CLICK_FAILED = createConst('TRACK_AD_CLICK_FAILED')
export function trackAdClick({ adId }) {
  return {
    [CALL_API]: {
      path: `/ads/${adId}/click`,
      method: 'post',
      sendingType: TRACK_AD_CLICK,
      successType: TRACK_AD_CLICK_SUCCESS,
      errorType: TRACK_AD_CLICK_FAILED
    }
  }
}

export const REPORT_RESTAURANT = createConst('REPORT_RESTAURANT')
export const REPORT_RESTAURANT_SUCCESS = createConst('REPORT_RESTAURANT_SUCCESS')
export const REPORT_RESTAURANT_FAILED = createConst('REPORT_RESTAURANT_FAILED')
export function reportRestaurant({ restaurantId, type, message }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/report`,
      method: 'post',
      body: {
        type: `${type}`,
        msg: message
      },
      sendingType: REPORT_RESTAURANT,
      successType: REPORT_RESTAURANT_SUCCESS,
      errorType: REPORT_RESTAURANT_FAILED,
    }
  }
}


export const GET_RECENT_POST = createConst('GET_RECENT_POST')
export const GET_RECENT_POST_SUCCESS = createConst('GET_RECENT_POST_SUCCESS')
export const GET_RECENT_POST_FAILED = createConst('GET_RECENT_POST_FAILED')
export function getRecentPost() {
  return {
    [CALL_API]: {
      // path: '/community/recently/',
      // method: 'get',
      // query: {
      //   limit: 20,
      //   offset: 0,
      //   sampleCnt: 11
      // },
      path: '/community/',
      method: 'get',
      query: {
        limit: 12,
        offset: 0,
        type: 'ifoodie',
        orderBy: 'created'
      },
      sendingType: GET_RECENT_POST,
      successType: GET_RECENT_POST_SUCCESS,
      errorType: GET_RECENT_POST_FAILED
    }
  }
}

