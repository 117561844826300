import React from 'react'
import Head from 'next/head'
import config from '@/config'
import Script from 'next/script'

export const renderGTM = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WJWQMXS');
`

export const renderProtico = function(currentPath) {
  var pathsToCheck = ['/restaurant/', '/post/'];
  var pathExists = false;
  if (pathsToCheck.some(path => currentPath.includes(path))) {
    pathExists = true;
  }
  if (currentPath.includes('explore')) {
    pathExists = false;
  }
  if (!window.mobileCheck() && pathExists) {
    (function(w, d, s, src, n) {
      let js, ajs = d.getElementsByTagName(s)[0]
      if (d.getElementById(n)) return
      js = d.createElement(s); js.id = n
      js.type = 'text/javascript'
      w[n] = w[n] || function() { (w[n].q = w[n].q || []).push(arguments) }; w[n].l = 1 * new Date()
      js.src = src; ajs.parentNode.insertBefore(js, ajs)
    })(window, document, 'script', 'https://main.protico.io/api/v1/ifoodie.tw/protico-frame.js', 'protico')
  }
}

const initDable = `
  (function(d,a,b,l,e,_) {
  d[b]=d[b]||function(){(d[b].q=d[b].q||[]).push(arguments)};e=a.createElement(l);
  e.async=1;e.charset='utf-8';e.src='//static.dable.io/dist/plugin.min.js';
  _=a.getElementsByTagName(l)[0];_.parentNode.insertBefore(e,_);
  })(window,document,'dable','script');
  dable('setService', 'ifoodie.tw');
  dable('sendLogOnce');
`

export const initInterstitial = function () {
  const path = window.location.pathname
  if (path.indexOf('/iframe') != -1) {
    return
  }

  window.showInterstitial = true
  window.interstitialReady = false
  window.interstitialRequested = false

  let interstitialSlot
  googletag.cmd.push(function () {
    interstitialSlot = googletag.defineOutOfPageSlot(
      '/230404055/ifoodie_interstitial_ADX',
      googletag.enums.OutOfPageFormat.INTERSTITIAL
    )
    if (interstitialSlot) {
      // do not show interstitial ad for unhide case
      window.interstitialSlot = interstitialSlot
      interstitialSlot.addService(googletag.pubads())
      googletag.pubads().addEventListener('slotOnload', function (event) {
        if (interstitialSlot === event.slot) {
          // console.log('🦔 interstitial 👍🏻✅')
          let event = new CustomEvent('interstitialReady', {})
          window.dispatchEvent(event)
          window.interstitialReady = true
        }
      })
      googletag
        .pubads()
        .addEventListener('slotResponseReceived', function (event) {
          if (interstitialSlot === event.slot) {
            // console.log('🦔 interstitial 👍🏻requested')
            window.interstitialRequested = true
          }
        })
      googletag.pubads().enableSingleRequest()
      googletag.enableServices()
      googletag.pubads().refresh([interstitialSlot])
    }
  })
}

export const handleFbPixel = function () {
  const path = window.location.pathname
  if (path.indexOf('/iframe') != -1) {
    const gtmFbPixel = {
      fbPixel: {
        content_name: path,
        content_category: 'User iframe',
        content_type: 'user',
        content_ids: [path],
      },
      fbPixelReady: true,
      fbEvent: 'ViewContent',
      event: 'fbPixelReadyEvent',
    }
    window.dataLayer.push(gtmFbPixel)
  }
}

const listenAndRecordScroll = function () {
  window.addEventListener('pageshow', function (e) {
    var helpBubble = document.getElementById('help-bubble')
    if (e.persisted) {
      helpBubble.style.backgroundColor = 'green'
      if (window.interstitialSlot) {
        googletag.destroySlots([window.interstitialSlot])
        // console.log(`💣 destroy interstitialSlot: `, window.interstitialSlot)
        googletag.cmd.push(function () {
          initInterstitial()
        })
      }
      setTimeout(() => {
        let event = new CustomEvent(
          'backToPersistedPage', { detail: {} }
        )
        window.dispatchEvent(event)
      }, 2000)
    }
  })
}

const deferInitScripts = `
  if (!window.deferInitScripts) {
    const listenAndRecordScroll = ${listenAndRecordScroll.toString()};
    listenAndRecordScroll();

    const initObj = {};
    window.deferInitScripts = initObj;
    initObj.deprioritizedScriptsRequested = false;
    initObj.isIframe = window.self !== window.top
    function requestScriptsAfterAdsRequested() {
      if (window.deferInitScripts.deprioritizedScriptsRequested) return;
      // Request deprioritized scripts here.
      console.log('requestScriptsAfterAdsRequested')
      window.deferInitScripts.deprioritizedScriptsRequested = true;
    }

    const initInterstitial = ${initInterstitial.toString()};
    initInterstitial();

    setTimeout(requestScriptsAfterAdsRequested, 1.5 * 1000);
  }
`

const initMobileCheck = `
  window.mobileCheck = function() {
    if (!navigator || !navigator.userAgent) {return False}
    if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ) {return true} else {return false}
  }
`

const initVMFive = `
  window.onVM5AdLiteReady = function() {
    if (window.mobileCheck()) {
      VM5AdLite.init({ appKey: '59b9f41d83ac97307177caab' })
    } else {
      VM5AdLite.init({ appKey: '5c6bc6bb89be9b7b3608c4e1' })
    }
  }
  window.vm5Loaded = function(vmFiveId){
    let event = new CustomEvent(
      'vm5AdLoadFail', { detail: {vmFiveId} }
    )
    window.dispatchEvent(event)
  }
`

const initADX = `
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];
  window.adxSlotDict = window.adxSlotDict || {};
  window.googletag.cmd.push(function() {
    googletag.pubads().disableInitialLoad();
  });
  window.adxBackFilled = function(adsenseSlot){
    let event = new CustomEvent(
      'adxBackFilled', { detail: {adsenseSlot} }
    )
    window.dispatchEvent(event)
  }
  window.adxHide = function(adxSlot){
    let event = new CustomEvent(
      'adxHide', { detail: {adxSlot} }
    )
    window.dispatchEvent(event)
  }
`

const initAotter = `
  (function(w, d, s, src, n) {
    var js, ajs = d.getElementsByTagName(s)[0];
    if (d.getElementById(n)) return;
    js = d.createElement(s); js.id = n;
    w[n] = w[n] || function() { (w[n].q = w[n].q || []).push(arguments) }; w[n].l = 1 * new Date();
    js.async = 1; js.src = src; ajs.parentNode.insertBefore(js, ajs)
  })(window, document, 'script', 'https://static.aottercdn.com/trek/sdk/3.5.4/sdk.js', 'AotterTrek');

  AotterTrek('init', 'AIfEjWZHFP2nv53a2Ok2');
  AotterTrek('send');
`

const initInnity = `
  var innity_adZoneAsync = innity_adZoneAsync || {};
  innity_adZoneAsync.q = innity_adZoneAsync.q || [];

  window.innityFail = {}
  window.innityPassback = function(innityKey) {
    window.innityFail[innityKey] = true
    let event = new CustomEvent(
      'innityAdLoadFail', { detail: {innityKey} }
    )
    window.dispatchEvent(event)
  }
`

const initTeads = `
  window.teadsPassback = function(teadsKey) {
    let event = new CustomEvent(
      'teadsAdLoadFail', { detail: {teadsKey} }
    )
    window.dispatchEvent(event)
  }
`

const initClickforce = `
  window.clickforcePassback = function(adZone) {
    let event = new CustomEvent(
      'clickforceAdLoadFail', { detail: {adZone} }
    )
    window.dispatchEvent(event)
  }
`

export default function DefaultHead({ currentUser }) {
  const email = currentUser.email ? currentUser.email : ''
  // prevent: SyntaxError: Identifier 'email' has already been declared
  const initGaForAd = `
    function _initGaForAd() {
      const email = '${email}'
      const data = {
        event: 'gaDataInitEvent'
      }
      if (email.length) {
        data.email = '${currentUser.email}'
        data.userId = '${currentUser.id}'
      }
      window.dataLayer.push(data);
    }
    _initGaForAd()
  `
  const sendFbPixel = `
    function _sendFbPixel() {
      if (!window._handleFbPixel) {
        setTimeout(() => {
          const email = '${email}'
          const data = {
            event: 'fbPixelInitEvent'
          }
          if (email.length) {
            data.email = '${currentUser.email}'
            data.userId = '${currentUser.id}'
          }
          window.dataLayer.push(data);
          window._handleFbPixel = ${handleFbPixel.toString()};
          window._handleFbPixel();
        }, 1000)
      }
    }
    _sendFbPixel()
  `

  const _renderScriptTag = (script, productionOnly = true) => {
    let dev = config.env === 'development'
    if ((productionOnly && !dev) || !productionOnly) {
      return <script dangerouslySetInnerHTML={{ __html: script }} />
    }
    return null
  }
  const _renderNoScriptTag = (script, productionOnly = true) => {
    let dev = config.env === 'development'
    if ((productionOnly && !dev) || !productionOnly) {
      return <noscript dangerouslySetInnerHTML={{ __html: script }} />
    }
    return null
  }
  return (
    <>
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        // href="/assets/favicons/apple-touch-icon.png"
        href="https://lh3.googleusercontent.com/N7PGUvTGMnAMurEwyl2dVUtd-gQVtjTanR4QcuSQsZyj9V5eqmwsFqsRdUF2HJ9Z0_D6xXuBFNLTC5KmKyzEv0_FXRkAVxaoDk8k65V8w9U=s0"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        // href="/assets/favicons/favicon-192x192.png"
        href="https://lh3.googleusercontent.com/i7blUNtIv-kp658P2Bf819aK5YFbf_Fo-7RsdfkdF6NVnLVwE_0xk2xifiGJimdqoXFfmtYeF5NIW5k4i743bPFx--7nJg8lEe_aabyJqj4=s192-rp"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        // href="/assets/favicons/favicon-32x32.png"
        href="https://lh3.googleusercontent.com/i7blUNtIv-kp658P2Bf819aK5YFbf_Fo-7RsdfkdF6NVnLVwE_0xk2xifiGJimdqoXFfmtYeF5NIW5k4i743bPFx--7nJg8lEe_aabyJqj4=s32-rp"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        // href="/assets/favicons/favicon-16x16.png"
        href="https://lh3.googleusercontent.com/i7blUNtIv-kp658P2Bf819aK5YFbf_Fo-7RsdfkdF6NVnLVwE_0xk2xifiGJimdqoXFfmtYeF5NIW5k4i743bPFx--7nJg8lEe_aabyJqj4=s16-rp"
      />
      <link
        rel="manifest"
        // href="/assets/favicons/manifest.json?v=2"
        href="https://cdn.jsdelivr.net/gh/sainteye/sainteye.github.io@master/manifest.json"
      />
      <link
        rel="mask-icon"
        href="/assets/favicons/safari-pinned-tab.svg"
        color="#0b5d90"
      />
      <link
        rel="shortcut icon"
        // href="/assets/favicons/favicon.ico?v=2"
        href="https://lh3.googleusercontent.com/k9lFOo1kxKcm3-NRuIE7JkRBuY4sUJD6MlbW9RnBPypW3YqAMZ9vx2MelvFJn_c3E7rIM1VjDr2_doMnKDJ3VgmIbT56WDRLaKZntDx56fkq=s0"
      />
      <title key="title">愛食記 - 台灣美食餐廳推薦，最好用的美食App</title>
      <meta
        key="viewport"
        name="viewport"
        content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=0"
      />
      <meta key="charSet" charSet="UTF-8" />
      <meta
        key="msapplication-config"
        name="msapplication-config"
        content="/assets/favicons/browserconfig.xml"
      />
      <meta key="theme-color" name="theme-color" content="#ffffff" />
      <meta key="application-name" name="application-name" content="愛食記" />
      <meta key="og:site_name" property="og:site_name" content="愛食記" />
      <meta key="og:locale" property="og:locale" content="zh_TW" />
      <meta
        key="fb:app_id"
        property="fb:app_id"
        content={config.facebookAppId}
      />
      <meta key="al:ios:app_name" property="al:ios:app_name" content="愛食記" />
      <meta
        key="al:ios:app_store_id"
        property="al:ios:app_store_id"
        content="757992884"
      />

      <meta
        key="al:web:should_fallback"
        property="al:web:should_fallback"
        content="true"
      />
      <meta
        key="description"
        name="description"
        content="愛食記幫你發現最新、最流行的美食餐廳!! 透過網站和App讓你快速探索全台美食，觀看網友的餐廳評價、部落客專業食記，並提供餐廳24小時線上訂位服務"
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="og:description"
        property="og:description"
        content="愛食記幫你發現最新、最流行的美食餐廳!! 透過網站和App讓你快速探索全台美食，觀看網友的餐廳評價、部落客專業食記，並提供餐廳24小時線上訂位服務"
      />
      <meta
        key="og:image"
        property="og:image"
        content="https://lh3.googleusercontent.com/sYii1pqEIDJHZbkRTqYbxuZLyhg75VHnuT9Yx6M9NCu_-g8zX_4dpS4oKmClBAbXEO_rEFpL1hVSp8HIR0CcVOt_1MXADz_k=s1800"
      />
      <meta
        key="og:title"
        property="og:title"
        content="愛食記 - 台灣美食餐廳推薦，最好用的美食App"
      />
      <meta key="author" name="author" content="愛食記" />
      <meta
        key="itemProp:image"
        itemProp="image"
        content="https://lh3.googleusercontent.com/sYii1pqEIDJHZbkRTqYbxuZLyhg75VHnuT9Yx6M9NCu_-g8zX_4dpS4oKmClBAbXEO_rEFpL1hVSp8HIR0CcVOt_1MXADz_k=s1800"
      />
      <meta
        id="iosUrl"
        key="al:ios:url"
        property="al:ios:url"
        content="tpblog:///applink"
      />
      <meta
        key="apple-itunes-app"
        name="apple-itunes-app"
        content="app-id=757992884"
      />
      {/* gliacloud */}
      <meta
        name="dailymotion-domain-verification"
        content="dmk1radgo2rj961tb"
      />
      {/* gliacloud */}

      {/* <script async src="/assets/js/e76cf125-2f2d-42a5-acc5-9da41c9e6b50.js" /> */}
      <link rel="preconnect" href="https://pagead2.googlesyndication.com" />
      <link rel="preconnect" href="https://connect.facebook.net" />
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
      {_renderScriptTag(initMobileCheck, false)}
      {_renderScriptTag(initADX, false)}
      {_renderScriptTag(initTeads, false)}
      {/* {_renderScriptTag(initAotter, false)} */}
      {/* Disable innity */}
      {/* <script
        async="async"
        type="text/javascript"
        src="https://cdn.innity.net/admanager-async.js"
      /> */}
      {_renderScriptTag(initInnity, false)}
      {/* <script
        async
        type="application/javascript"
        src="//anymind360.com/js/5300/ats.js"
      /> */}
      {_renderScriptTag(initVMFive, false)}
      {/* {_renderNoScriptTag(initFacebookNoScript, false)} */}
      {_renderScriptTag(deferInitScripts, false)}
      {/* Move initGTM from defer to head */}
      {_renderScriptTag(renderGTM, false)}
      {_renderScriptTag(initGaForAd, false)}
      {_renderScriptTag(sendFbPixel, false)}
    </Head>
    {/* <script
      async
      src={`/assets/js/prebidConfig.js?v=${config.staticVersion}`}
    /> */}
    {/* <
      async
      src={`/assets/js/prebid7.54.1.js?v=${config.staticVersion}`}
    /> */}
    {/* <
      async
      src={`/assets/js/prebid8.27.0.js?v=${config.staticVersion}`}
    /> */}

    <Script
      strategy='beforeInteractive'
      // Header Bidding
      src='https://cdn.jsdelivr.net/gh/sainteye/sainteye.github.io@fb85f956c83a97905d626c83d48844d584e2586a/js/prebid/prebidConfig.js'
    />
    <Script
      strategy='beforeInteractive'
      src="https://cdn.jsdelivr.net/gh/sainteye/sainteye.github.io@f8d9919a2d8c0e7a024dfae71cc119801e85558d/js/prebid/prebid8.27.0.js"
    />
    <Script
      strategy='beforeInteractive'
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    />
    <Script
      strategy='beforeInteractive'
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
    />
    </>
  )
}
