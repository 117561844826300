import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import superagent from 'superagent'

const createConst = constantCreator('dish')

export const GET_DISH_INFO = createConst('GET_DISH_INFO')
export const GET_DISH_INFO_SUCCESS = createConst('GET_DISH_INFO_SUCCESS')
export const GET_DISH_INFO_FAILED = createConst('GET_DISH_INFO_FAILED')
export function getDishInfo({ dishId }) {
  return {
    [CALL_API]: {
      path: `/dish/${dishId}/`,
      method: 'get',
      limit: 40,
      sendingType: GET_DISH_INFO,
      successType: GET_DISH_INFO_SUCCESS,
      errorType: GET_DISH_INFO_FAILED
    }
  }
}

export const GET_DISH_DETAIL = createConst('GET_DISH_DETAIL')
export function clearDishDetail() {
  return {
    type: GET_DISH_DETAIL
  }
}

export const GET_DISH_PHOTOS = createConst('GET_DISH_PHOTOS')
export const GET_DISH_PHOTOS_SUCCESS = createConst('GET_DISH_PHOTOS_SUCCESS')
export const GET_DISH_PHOTOS_FAILED = createConst('GET_DISH_PHOTOS_FAILED')
export const GET_DISH_PHOTOS_MORE = createConst('GET_DISH_PHOTOS_MORE')
export const GET_DISH_PHOTOS_MORE_SUCCESS = createConst('GET_DISH_PHOTOS_MORE_SUCCESS')
export const GET_DISH_PHOTOS_MORE_FAILED = createConst('GET_DISH_PHOTOS_MORE_FAILED')
export function getDishPhotos({ dishId, offset, limit = 20 }) {
  return {
    dishId,
    limit,
    [CALL_API]: {
      path: `/dish/${dishId}/photo/`,
      method: 'get',
      query: omitBy({
        offset,
        limit
      }, isNil),
      sendingType: offset ? GET_DISH_PHOTOS_MORE : GET_DISH_PHOTOS,
      successType: offset ? GET_DISH_PHOTOS_MORE_SUCCESS : GET_DISH_PHOTOS_SUCCESS,
      errorType: offset ? GET_DISH_PHOTOS_MORE_FAILED : GET_DISH_PHOTOS_FAILED
    }
  }
}

export const GET_DISH_REVIEWS = createConst('GET_DISH_REVIEWS')
export const GET_DISH_REVIEWS_SUCCESS = createConst('GET_DISH_REVIEWS_SUCCESS')
export const GET_DISH_REVIEWS_FAILED = createConst('GET_DISH_REVIEWS_FAILED')
export const GET_DISH_REVIEWS_MORE = createConst('GET_DISH_REVIEWS_MORE')
export const GET_DISH_REVIEWS_MORE_SUCCESS = createConst('GET_DISH_REVIEWS_MORE_SUCCESS')
export const GET_DISH_REVIEWS_MORE_FAILED = createConst('GET_DISH_REVIEWS_MORE_FAILED')
export function getDishReviews({ dishId, offset, limit = 20 }) {
  return {
    dishId,
    limit,
    [CALL_API]: {
      path: `/dish/${dishId}/review/`,
      method: 'get',
      query: omitBy({
        offset,
        limit
      }, isNil),
      sendingType: offset ? GET_DISH_REVIEWS_MORE : GET_DISH_REVIEWS,
      successType: offset ? GET_DISH_REVIEWS_MORE_SUCCESS : GET_DISH_REVIEWS_SUCCESS,
      errorType: offset ? GET_DISH_REVIEWS_MORE_FAILED : GET_DISH_REVIEWS_FAILED
    }
  }
}

export const RECOMMEND_DISH = createConst('RECOMMEND_DISH')
export const RECOMMEND_DISH_SUCCESS = createConst('RECOMMEND_DISH_SUCCESS')
export const RECOMMEND_DISH_FAILED = createConst('RECOMMEND_DISH_FAILED')
export function recommendDish({ dishId }) {
  return {
    dishId,
    [CALL_API]: {
      path: `/dish/${dishId}/recommend/`,
      method: 'post',
      sendingType: RECOMMEND_DISH,
      successType: RECOMMEND_DISH_SUCCESS,
      errorType: RECOMMEND_DISH_FAILED
    }
  }
}

export const UNRECOMMEND_DISH = createConst('UNRECOMMEND_DISH')
export const UNRECOMMEND_DISH_SUCCESS = createConst('UNRECOMMEND_DISH_SUCCESS')
export const UNRECOMMEND_DISH_FAILED = createConst('UNRECOMMEND_DISH_FAILED')
export function unrecommendDish({ dishId }) {
  return {
    dishId,
    [CALL_API]: {
      path: `/dish/${dishId}/recommend/`,
      method: 'delete',
      sendingType: UNRECOMMEND_DISH,
      successType: UNRECOMMEND_DISH_SUCCESS,
      errorType: UNRECOMMEND_DISH_FAILED
    }
  }
}

export const SEND_DISH_REVIEW = createConst('SEND_DISH_REVIEW')
export const SEND_DISH_REVIEW_SUCCESS = createConst('SEND_DISH_REVIEW_SUCCESS')
export const SEND_DISH_REVIEW_FAILED = createConst('SEND_DISH_REVIEW_FAILED')
export function sendDishReview({ dishId, message, rating, restaurantId }) {
  return {
    dishId,
    restaurantId,
    [CALL_API]: {
      path: `/dish/${dishId}/review/`,
      method: 'post',
      body: omitBy({
        message,
        rating
      }, isNil),
      sendingType: SEND_DISH_REVIEW,
      successType: SEND_DISH_REVIEW_SUCCESS,
      errorType: SEND_DISH_REVIEW_FAILED,
      afterError: () => {
        alert('連線失敗，尚未送出評論')
      }
    }
  }
}


export const UPDATE_UPLOAD_IMAGE_PROGRESS = createConst('UPDATE_UPLOAD_IMAGE_PROGRESS')
export function updateUploadImageProgress({ progress }) {
  return {
    type: UPDATE_UPLOAD_IMAGE_PROGRESS,
    progress
  }
}

export const UPLOAD_IMAGE = createConst('UPLOAD_IMAGE')
export const UPLOAD_IMAGE_SUCCESS = createConst('UPLOAD_IMAGE_SUCCESS')
export const UPLOAD_IMAGE_FAILED = createConst('UPLOAD_IMAGE_FAILED')
export function uploadImageSuccess({ imgUrl }) {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    imgUrl
  }
}

export const ADD_DISH_PHOTO = createConst('ADD_DISH_PHOTO')
export const ADD_DISH_PHOTO_SUCCESS = createConst('ADD_DISH_PHOTO_SUCCESS')
export const ADD_DISH_PHOTO_FAILED = createConst('ADD_DISH_PHOTO_FAILED')
export function addDishPhoto({ dishId, photoUrl, restaurantId }) {
  return {
    dishId,
    photoUrl,
    restaurantId,
    [CALL_API]: {
      path: `/dish/${dishId}/photo/`,
      method: 'post',
      body: { photoUrl },
      sendingType: ADD_DISH_PHOTO,
      successType: ADD_DISH_PHOTO_SUCCESS,
      errorType: ADD_DISH_PHOTO_FAILED
    }
  }
}

export const GET_UPLOAD_IMAGE_URL = createConst('GET_UPLOAD_IMAGE_URL')
export const GET_UPLOAD_IMAGE_URL_SUCCESS = createConst('GET_UPLOAD_IMAGE_URL_SUCCESS')
export const GET_UPLOAD_IMAGE_URL_FAILED = createConst('GET_UPLOAD_IMAGE_URL_FAILED')
export function uploadImageAndAddDishPhoto({ imgFile, dishId, restaurantId }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/globals/get_upload_image_url/',
      sendingType: GET_UPLOAD_IMAGE_URL,
      successType: GET_UPLOAD_IMAGE_URL_SUCCESS,
      errorType: GET_UPLOAD_IMAGE_URL_FAILED,
      afterSuccess: ({ dispatch, response }) => {
        let uploadUrl = response.response.url
        let data = new FormData()
        data.append('file', imgFile)
        superagent
          .post(uploadUrl)
          .send(data)
          .on('progress',(progress)=>{
            dispatch(updateUploadImageProgress({ progress: Math.round(progress.percent) }))
          }).end((err, res)=>{
            dispatch(uploadImageSuccess({imgUrl: `${res.body.url}=s600`}))
            dispatch(addDishPhoto({
              dishId, restaurantId,
              photoUrl: `${res.body.url}=s600`
            }))
          })
      }
    }
  }
}

export const REPORT_DISH = createConst('REPORT_DISH')
export const REPORT_DISH_SUCCESS = createConst('REPORT_DISH_SUCCESS')
export const REPORT_DISH_FAILED = createConst('REPORT_DISH_FAILED')
export function reportDish({ dishId, message, type, photoId, reviewId, price }) {
  return {
    [CALL_API]: {
      path: `/dish/${dishId}/report`,
      method: 'post',
      body: omitBy({
        type: `${type}`,
        msg: message,
        photoId,
        reviewId,
        price
      }, isNil),
      sendingType: REPORT_DISH,
      successType: REPORT_DISH_SUCCESS,
      errorType: REPORT_DISH_FAILED,
      afterSuccess: () => {
        alert('回報已送出')
      }
    }
  }
}

export const DELETE_DISH_PHOTO = createConst('DELETE_DISH_PHOTO')
export const DELETE_DISH_PHOTO_SUCCESS = createConst('DELETE_DISH_PHOTO_SUCCESS')
export const DELETE_DISH_PHOTO_FAILED = createConst('DELETE_DISH_PHOTO_FAILED')
export function deleteDishPhoto({ photoId }) {
  return {
    photoId,
    [CALL_API]: {
      path: `/photo/${photoId}/`,
      method: 'delete',
      sendingType: DELETE_DISH_PHOTO,
      successType: DELETE_DISH_PHOTO_SUCCESS,
      errorType: DELETE_DISH_PHOTO_FAILED,
    }
  }
}

export const ADD_DISH = createConst('ADD_DISH')
export const ADD_DISH_SUCCESS = createConst('ADD_DISH_SUCCESS')
export const ADD_DISH_FAILED = createConst('ADD_DISH_FAILED')
export function addDish({ restaurantId, name, price }) {
  return {
    restaurantId,
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/dish/`,
      method: 'post',
      body: omitBy({
        name,
        price
      }, isNil),
      sendingType: ADD_DISH,
      successType: ADD_DISH_SUCCESS,
      errorType: ADD_DISH_FAILED
    }
  }
}

export const GET_DISH_LIST = createConst('GET_DISH_LIST')
export const GET_DISH_LIST_SUCCESS = createConst('GET_DISH_LIST_SUCCESS')
export const GET_DISH_LIST_FAILED = createConst('GET_DISH_LIST_FAILED')
export function getDishList({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/restaurant/${restaurantId}/dish`,
      method: 'get',
      query: {
        limit: 40,
      },
      sendingType: GET_DISH_LIST,
      successType: GET_DISH_LIST_SUCCESS,
      errorType: GET_DISH_LIST_FAILED,
    },
  }
}
