import { fromJS } from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/business'

export const initialState = fromJS({
  _hasInit: false,

  restaurants: [],
  cards: [],
  listState: {
    isLoading: true,
  },
  createState: {
    isLoading: false,
    errorMessage: '',
  },
  application: {
    isLoading: false,
    errorMessage: '',
  },
  applications: {},
  confirmation: {
    isLoading: false,
    errorMessage: '',
    success: false,
  },
  createSuccessCount: 0,
})

const REDUCER_NODE = 'business'

export default function business(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.CONFIRM_APPLICATION:
      return state
        .setIn(['confirmation', 'isLoading'], true)
        .setIn(['confirmation', 'errorMessage'], '')
        .setIn(['confirmation', 'success'], false)

    case ActionType.CONFIRM_APPLICATION_SUCCESS:
      return state
        .setIn(['confirmation', 'isLoading'], false)
        .setIn(['confirmation', 'errorMessage'], '')
        .setIn(['confirmation', 'success'], true)

    case ActionType.CONFIRM_APPLICATION_FAILED:
      return state
        .setIn(['confirmation', 'isLoading'], false)
        .setIn(['confirmation', 'errorMessage'], '發生錯誤')
        .setIn(['confirmation', 'success'], false)

    case ActionType.GET_APPLICATION:
      return state.setIn(
        ['applications', action.applyId],
        fromJS({
          isLoading: true,
        })
      )

    case ActionType.GET_APPLICATION_SUCCESS:
      const { response } = action.response
      return state.setIn(
        ['applications', action.applyId],
        fromJS({
          isLoading: false,
          restaurant: response.restaurantName,
          address: response.restaurantAddress,
          user: response.user,
          certifiedImg: response.certifiedImg,
        })
      )

    case ActionType.GET_APPLICATION_FAILED:
      return state.setIn(
        ['applications', action.applyId],
        fromJS({
          isLoading: false,
          errorMessage: 'failed',
        })
      )

    case ActionType.BUSINESS_APPLY:
      return state
        .setIn(['application', 'isLoading'], true)
        .setIn(['application', 'errorMessage'], '')

    case ActionType.BUSINESS_APPLY_SUCCESS:
      return state
        .setIn(['application', 'isLoading'], false)
        .setIn(['application', 'errorMessage'], '')

    case ActionType.BUSINESS_APPLY_FAILED:
      return state
        .setIn(['application', 'isLoading'], false)
        .setIn(
          ['application', 'errorMessage'],
          '發生錯誤，請檢查email格式，並通知業務人員'
        )

    case ActionType.CLEAR_BUSINESS_DATA:
      return initialState

    case ActionType.CREATE_CARD:
      return state
        .setIn(['createState', 'isLoading'], true)
        .setIn(['createState', 'errorMessage'], '')

    case ActionType.CREATE_CARD_SUCCESS:
      return state
        .update('createSuccessCount', (prev) => prev + 1)
        .setIn(['createState', 'isLoading'], false)
        .setIn(['createState', 'errorMessage'], '')
        .update('cards', (prev) =>
          prev.concat(fromJS([action.response.response]))
        )

    case ActionType.CREATE_CARD_FAILED:
      return state
        .setIn(['createState', 'isLoading'], false)
        .setIn(['createState', 'errorMessage'], '發生錯誤，尚未創建卡片')

    case ActionType.GET_BUSINESS_RESTAURANTS_SUCCESS:
      return state.set('restaurants', fromJS(action.response.response))

    case ActionType.GET_RESTAURANT_CARDS:
      return state.setIn(['listState', 'isLoading'], true)

    case ActionType.GET_RESTAURANT_CARDS_SUCCESS:
      return state
        .setIn(['listState', 'isLoading'], false)
        .set('cards', fromJS(action.response.response))

    case ActionType.UPDATE_CARD_STATE_SUCCESS:
      return state.update('cards', (prev) => {
        const index = prev.findIndex(
          (card) => card.get('id') === action.response.response.id
        )
        if (index >= 0) {
          return prev.set(index, fromJS(action.response.response))
        }
        return prev
      })

    default:
      return state
  }
}
