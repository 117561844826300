import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
import toSnake from '@/libs/toSnake'
import superagent from 'superagent'
import { window } from '@/libs/globalUtils'

const createConst = constantCreator('blogger')

export const IMAGE_UPLOAD_PREPARE = createConst('IMAGE_UPLOAD_PREPARE')
export function imageUploadPrepare({
  width,
  height,
  src,
  tag,
  index,
  localVersionHash,
}) {
  return {
    type: IMAGE_UPLOAD_PREPARE,
    tag,
    width,
    height,
    src,
    index,
    localVersionHash,
  }
}

export const IMAGE_UPLOAD_PROGRESS = createConst('IMAGE_UPLOAD_PROGRESS')
export function imageUploadProgress({ tag, percent, localVersionHash }) {
  return {
    type: IMAGE_UPLOAD_PROGRESS,
    percent,
    tag,
    localVersionHash,
  }
}

export const IMAGE_UPLOAD_SUCCESS = createConst('IMAGE_UPLOAD_SUCCESS')
export function imageUploadSuccess({
  url,
  tag,
  width,
  height,
  localVersionHash,
}) {
  return {
    type: IMAGE_UPLOAD_SUCCESS,
    url,
    width,
    height,
    tag,
    localVersionHash,
  }
}

export const GET_UPLOAD_IMAGE_URL = createConst('GET_UPLOAD_IMAGE_URL')
export const GET_UPLOAD_IMAGE_URL_SUCCESS = createConst(
  'GET_UPLOAD_IMAGE_URL_SUCCESS'
)
export const GET_UPLOAD_IMAGE_URL_FAILED = createConst(
  'GET_UPLOAD_IMAGE_URL_FAILED'
)
export function uploadImage({
  imgFile,
  width,
  height,
  src,
  tag,
  index,
  localVersionHash,
}) {
  return (dispatch) => {
    dispatch(
      imageUploadPrepare({ width, height, src, tag, index, localVersionHash })
    )
    dispatch(
      imageUploadProgress({ tag, percent: '上傳中...', localVersionHash })
    )
    dispatch(
      performUploadImage({
        imgFile,
        width,
        height,
        tag,
        localVersionHash,
      })
    )
  }
}

function performUploadImage({ imgFile, width, height, tag, localVersionHash }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/globals/get_upload_image_url/',
      sendingType: GET_UPLOAD_IMAGE_URL,
      successType: GET_UPLOAD_IMAGE_URL_SUCCESS,
      errorType: GET_UPLOAD_IMAGE_URL_FAILED,
      afterSuccess: ({ dispatch, response }) => {
        const uploadUrl = response.response.url
        let data = new FormData()
        data.append('file', imgFile)
        superagent
          .post(uploadUrl)
          .send(data)
          .on('progress', (progress) => {
            const percent = Math.round(progress.percent)
            dispatch(
              imageUploadProgress({
                tag,
                percent:
                  percent == 100
                    ? '上傳完成，請稍候圖片處理...'
                    : `已上傳 ${percent}%...`,
                localVersionHash,
              })
            )
          })
          .end((err, res) => {
            dispatch(
              imageUploadSuccess({
                url: `${res.body.url}=s600`,
                tag,
                width,
                height,
                localVersionHash,
              })
            )
          })
      },
    },
  }
}


export const INIT_EDITOR_WITH_DATA = createConst('INIT_EDITOR_WITH_DATA')
export function initEditorWithData({ postData, title, timestamp, refUrl }) {
  return {
    type: INIT_EDITOR_WITH_DATA,
    postData,
    title,
    timestamp,
    refUrl,
  }
}


export const SET_BLOG_TYPE = createConst('SET_BLOG_TYPE')
export const SET_BLOG_TYPE_SUCCESS = createConst('SET_BLOG_TYPE_SUCCESS')
export const SET_BLOG_TYPE_FAILED = createConst('SET_BLOG_TYPE_FAILED')

export function setBlogType({ blogId, blogType, isPaid, price, rating }) {
  const body = {
    blogType,
    isPaid,
  }
  if (price) {
    body.price = price
  }
  if (rating) {
    body.rating = rating
  }

  return {
    blogType,
    price,
    rating,
    [CALL_API]: {
      path: `/blog/${blogId}/blog_type/`,
      method: 'post',
      body,
      sendingType: SET_BLOG_TYPE,
      successType: SET_BLOG_TYPE_SUCCESS,
      errorType: SET_BLOG_TYPE_FAILED,
      afterSuccess: ({ response }) => {
        if (response.success) {
          console.log('👍success submit')
          window.location.reload()
        } else {
          console.log('❌failed submit')
        }
      },
    },
  }
}

export const EDITOR_SYNC = createConst('EDITOR_SYNC')
export const EDITOR_SYNC_SUCCESS = createConst('EDITOR_SYNC_SUCCESS')
export const EDITOR_SYNC_COMPLETE = createConst('EDITOR_SYNC_COMPLETE')
export const EDITOR_SYNC_FAILED = createConst('EDITOR_SYNC_FAILED')

export function syncToServer({
  data,
  blogId,
  title,
  versionHash,
  date,
  refUrl,
}) {
  let body = {
    hashCode: versionHash,
    title,
    paraListJson: JSON.stringify(toSnake(data)),
  }
  if (date) {
    body.date = date
  }
  if (refUrl) {
    body.refUrl = refUrl
  }
  if (blogId) {
    body.blogId = blogId
  }
  return {
    versionHash,
    [CALL_API]: {
      path: blogId ? `/blog/${blogId}/post/` : '/post/',
      method: 'post',
      body,
      sendingType: EDITOR_SYNC,
      successType: EDITOR_SYNC_COMPLETE,
      errorType: EDITOR_SYNC_FAILED,
      afterSuccess: ({ dispatch, response }) => {
        if (response.success) {
          console.log('✅success saved')
          dispatch({ type: EDITOR_SYNC_SUCCESS, response })
          window.history.replaceState(
            null,
            '愛食記 | 發表文章',
            `/blogger/blogs/edit/${response.response.blogId}`
          )
        } else {
          console.log('❌failed saved')
          dispatch({ type: EDITOR_SYNC_FAILED })
        }
      },
    },
  }
}

export const EDITOR_UPDATE_LOCAL = createConst('EDITOR_UPDATE_LOCAL')
export function updateLocalData({ data, id, title, editorVersionHash, date }) {
  return {
    type: EDITOR_UPDATE_LOCAL,
    data,
    id,
    title,
    editorVersionHash,
    date,
  }
}


export const GET_BLOG = createConst('GET_BLOG')
export const GET_BLOG_SUCCESS = createConst('GET_BLOG_SUCCESS')
export const GET_BLOG_FAILED = createConst('GET_BLOG_FAILED')

export function getBlog({ blogId }) {
  return {
    [CALL_API]: {
      path: `/blog/${blogId}/`,
      method: 'get',
      sendingType: GET_BLOG,
      successType: GET_BLOG_SUCCESS,
      errorType: GET_BLOG_FAILED,
    },
  }
}


export const SET_BLOG_COVER = createConst('SET_BLOG_COVER')
export const SET_BLOG_COVER_SUCCESS = createConst('SET_BLOG_COVER_SUCCESS')
export const SET_BLOG_COVER_FAILED = createConst('SET_BLOG_COVER_FAILED')

export function setBlogCover({ blogId, coverUrl }) {
  return {
    coverUrl,
    [CALL_API]: {
      path: `/optadmin/blog/change_cover`,
      method: 'post',
      body: {
        blogId,
        coverUrl,
      },
      sendingType: SET_BLOG_COVER,
      successType: SET_BLOG_COVER_SUCCESS,
      errorType: SET_BLOG_COVER_FAILED,
    },
  }
}

export const GET_POST = createConst('GET_POST')
export const GET_POST_SUCCESS = createConst('GET_POST_SUCCESS')
export const GET_POST_FAILED = createConst('GET_POST_FAILED')

export function getPost({ blogId }) {
  return {
    blogId,
    [CALL_API]: {
      path: `/blog/${blogId}/post/`,
      method: 'get',
      sendingType: GET_POST,
      successType: GET_POST_SUCCESS,
      errorType: GET_POST_FAILED,
    },
  }
}


export const USER_POST_DRAFT = 'USER_POST_DRAFT'
export const USER_POST_PUBLIC = 'USER_POST_PUBLIC'
export const USER_POST_REVIEWING = 'USER_POST_REVIEWING'
export const USER_POST_REJECTED = 'USER_POST_REJECTED'

export const BlogListType = {
  USER_POST_DRAFT: 202,
  USER_POST_PUBLIC: 'published',
  USER_POST_REVIEWING: 203,
  USER_POST_REJECTED: 205,
}

export const GET_BLOGGER_SUCCESS = createConst('GET_BLOGGER_SUCCESS')
export function getBloggerData({ userId }) {
  return {
    [CALL_API]: {
      path: `/user/${userId}/`,
      method: 'get',
      successType: GET_BLOGGER_SUCCESS,
    },
  }
}

export const GET_BLOG_LIST_SUCCESS = createConst('GET_BLOG_LIST_SUCCESS')

export function getUserBlogs({ userId, listType, offset }) {
  return {
    listType,
    [CALL_API]: {
      path: `/user/${userId}/blogs`,
      query: {
        blogType: BlogListType[listType],
        offset,
      },
      method: 'get',
      successType: GET_BLOG_LIST_SUCCESS,
    },
  }
}


export const DELETE_BLOG = createConst('DELETE_BLOG')
export const DELETE_BLOG_SUCCESS = createConst('DELETE_BLOG_SUCCESS')
export const DELETE_BLOG_FAILED = createConst('DELETE_BLOG_FAILED')

export function deleteBlog({ listType, blogId }) {
  return {
    listType,
    blogId,
    [CALL_API]: {
      path: `/blog/${blogId}/delete/`,
      method: 'post',
      sendingType: DELETE_BLOG,
      successType: DELETE_BLOG_SUCCESS,
      errorType: DELETE_BLOG_FAILED,
    },
  }
}


export const RATING_BLOG = createConst('RATING_BLOG')
export const RATING_BLOG_SUCCESS = createConst('RATING_BLOG_SUCCESS')
export const RATING_BLOG_FAILED = createConst('RATING_BLOG_FAILED')

export function ratingBlog({ listType, blogId, rating }) {
  return {
    listType,
    blogId,
    rating,
    [CALL_API]: {
      path: `/blog/${blogId}/`,
      method: 'post',
      body: { rating },
      sendingType: RATING_BLOG,
      successType: RATING_BLOG_SUCCESS,
      errorType: RATING_BLOG_FAILED,
    },
  }
}


