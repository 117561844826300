import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('cards')

export const GET_CARDS = createConst('GET_CARDS')
export const GET_CARDS_SUCCESS = createConst('GET_CARDS_SUCCESS')
export const GET_CARDS_FAILED = createConst('GET_CARDS_FAILED')

export function getCards({ listType, city, limit, offset }) {
  const v = 1
  return {
    listType,
    [CALL_API]: {
      path: '/card/recently',
      method: 'get',
      query: { city, limit, offset, v },
      sendingType: GET_CARDS,
      successType: GET_CARDS_SUCCESS,
      errorType: GET_CARDS_FAILED
    }
  }
}

export const GET_CARD_INFO = createConst('GET_CARD_INFO')
export const GET_CARD_INFO_SUCCESS = createConst('GET_CARD_INFO_SUCCESS')
export const GET_CARD_INFO_FAILED = createConst('GET_CARD_INFO_FAILED')
export function getCardInfo({ cardId }) {
  return {
    [CALL_API]: {
      path: `/card/${cardId}/`,
      method: 'get',
      sendingType: GET_CARD_INFO,
      successType: GET_CARD_INFO_SUCCESS,
      errorType: GET_CARD_INFO_FAILED
    }
  }
}


// TODO
// export const GET_RECOMMEND_RESTAURANTS = createConst('GET_RECOMMEND_RESTAURANTS')
// export const GET_RECOMMEND_RESTAURANTS_SUCCESS = createConst('GET_RECOMMEND_RESTAURANTS_SUCCESS')
// export const GET_RECOMMEND_RESTAURANTS_FAILED = createConst('GET_RECOMMEND_RESTAURANTS_FAILED')
// export function getRecommendRestaurants({ restaurantId }) {
//   return {
//     [CALL_API]: {
//       path: `/restaurant/${restaurantId}/recommend/`,
//       method: 'get',
//       sendingType: GET_RECOMMEND_RESTAURANTS,
//       successType: GET_RECOMMEND_RESTAURANTS_SUCCESS,
//       errorType: GET_RECOMMEND_RESTAURANTS_FAILED
//     }
//   }
// }

// export const GET_BLOG_LIST = createConst('GET_BLOG_LIST')
// export const GET_BLOG_LIST_SUCCESS = createConst('GET_BLOG_LIST_SUCCESS')
// export const GET_BLOG_LIST_FAILED = createConst('GET_BLOG_LIST_FAILED')
// export function getBlogList({ restaurantId, offset, limit }) {
//   return {
//     [CALL_API]: {
//       path: `/restaurant/${restaurantId}/blogs/`,
//       method: 'get',
//       query: { offset, limit },
//       sendingType: GET_BLOG_LIST,
//       successType: GET_BLOG_LIST_SUCCESS,
//       errorType: GET_BLOG_LIST_FAILED
//     }
//   }
// }

// export const GET_BLOG_LIST_MORE = createConst('GET_BLOG_LIST_MORE')
// export const GET_BLOG_LIST_MORE_SUCCESS = createConst('GET_BLOG_LIST_MORE_SUCCESS')
// export const GET_BLOG_LIST_MORE_FAILED = createConst('GET_BLOG_LIST_MORE_FAILED')
// export function getBlogListMore({ restaurantId, offset, limit }) {
//   return {
//     [CALL_API]: {
//       path: `/restaurant/${restaurantId}/blogs/`,
//       method: 'get',
//       query: { offset, limit },
//       sendingType: GET_BLOG_LIST_MORE,
//       successType: GET_BLOG_LIST_MORE_SUCCESS,
//       errorType: GET_BLOG_LIST_MORE_FAILED
//     },
//     offset
//   }
// }

// export const GET_ALL_CATEGORY = createConst('GET_ALL_CATEGORY')
// export const GET_ALL_CATEGORY_SUCCESS = createConst('GET_ALL_CATEGORY_SUCCESS')
// export const GET_ALL_CATEGORY_FAILED = createConst('GET_ALL_CATEGORY_FAILED')
// export function getAllCategory() {
//   return {
//     [CALL_API]: {
//       path: '/category/list/',
//       method: 'get',
//       sendingType: GET_ALL_CATEGORY,
//       successType: GET_ALL_CATEGORY_SUCCESS,
//       errorType: GET_ALL_CATEGORY_FAILED
//     }
//   }
// }

// export const REPORT_RESTAURANT = createConst('REPORT_RESTAURANT')
// export const REPORT_RESTAURANT_SUCCESS = createConst('REPORT_RESTAURANT_SUCCESS')
// export const REPORT_RESTAURANT_FAILED = createConst('REPORT_RESTAURANT_FAILED')
// export function reportRestaurant({ restaurantId, type, message }) {
//   return {
//     [CALL_API]: {
//       path: `/restaurant/${restaurantId}/report`,
//       method: 'post',
//       body: {
//         type: `${type}`,
//         msg: message
//       },
//       sendingType: REPORT_RESTAURANT,
//       successType: REPORT_RESTAURANT_SUCCESS,
//       errorType: REPORT_RESTAURANT_FAILED,
//     }
//   }
// }


// export const GET_RECENT_POST = createConst('GET_RECENT_POST')
// export const GET_RECENT_POST_SUCCESS = createConst('GET_RECENT_POST_SUCCESS')
// export const GET_RECENT_POST_FAILED = createConst('GET_RECENT_POST_FAILED')
// export function getRecentPost() {
//   return {
//     [CALL_API]: {
//       path: '/community/recently/',
//       method: 'get',
//       query: {
//         limit: 20,
//         offset: 0,
//         sampleCnt: 11
//       },
//       sendingType: GET_RECENT_POST,
//       successType: GET_RECENT_POST_SUCCESS,
//       errorType: GET_RECENT_POST_FAILED
//     }
//   }
// }

