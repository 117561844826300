import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('community')

export const LIKE_ARTICLE = createConst('LIKE_ARTICLE')
export const LIKE_ARTICLE_SUCCESS = createConst('LIKE_ARTICLE_SUCCESS')
export const LIKE_ARTICLE_FAILED = createConst('LIKE_ARTICLE_FAILED')
export function likeArticle({ articleId }) {
  return {
    articleId,
    [CALL_API]: {
      path: `/community/${articleId}/like`,
      method: 'post',
      sendingType: LIKE_ARTICLE,
      successType: LIKE_ARTICLE_SUCCESS,
      errorType: LIKE_ARTICLE_FAILED
    }
  }
}


export const UNLIKE_ARTICLE = createConst('UNLIKE_ARTICLE')
export const UNLIKE_ARTICLE_SUCCESS = createConst('UNLIKE_ARTICLE_SUCCESS')
export const UNLIKE_ARTICLE_FAILED = createConst('UNLIKE_ARTICLE_FAILED')
export function unlikeArticle({ articleId }) {
  return {
    articleId,
    [CALL_API]: {
      path: `/community/${articleId}/like`,
      method: 'delete',
      sendingType: UNLIKE_ARTICLE,
      successType: UNLIKE_ARTICLE_SUCCESS,
      errorType: UNLIKE_ARTICLE_FAILED
    }
  }
}

export const LIKE_REPLY = createConst('LIKE_REPLY')
export const LIKE_REPLY_SUCCESS = createConst('LIKE_REPLY_SUCCESS')
export const LIKE_REPLY_FAILED = createConst('LIKE_REPLY_FAILED')
export function likeReply({ replyId }) {
  return {
    replyId,
    [CALL_API]: {
      path: `/community/reply/${replyId}/like`,
      method: 'post',
      sendingType: LIKE_REPLY,
      successType: LIKE_REPLY_SUCCESS,
      errorType: LIKE_REPLY_FAILED
    }
  }
}


export const UNLIKE_REPLY = createConst('UNLIKE_REPLY')
export const UNLIKE_REPLY_SUCCESS = createConst('UNLIKE_REPLY_SUCCESS')
export const UNLIKE_REPLY_FAILED = createConst('UNLIKE_REPLY_FAILED')
export function unlikeReply({ replyId }) {
  return {
    replyId,
    [CALL_API]: {
      path: `/community/reply/${replyId}/like`,
      method: 'delete',
      sendingType: UNLIKE_REPLY,
      successType: UNLIKE_REPLY_SUCCESS,
      errorType: UNLIKE_REPLY_FAILED
    }
  }
}



export const UPDATE_LIKE_MAP = createConst('UPDATE_LIKE_MAP')
export function updateLikeMap({ articles, comments }) {
  return {
    type: UPDATE_LIKE_MAP,
    articles,
    comments
  }
}

export const UPDATE_LIKE_MAP_SINGLE = createConst('UPDATE_LIKE_MAP_SINGLE')
export function updateLikeMapSingle({ articleId, isLike }) {
  return {
    type: UPDATE_LIKE_MAP_SINGLE,
    isLike,
    articleId
  }
}
