import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('news')
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export const GET_BLOG_LIST = createConst('GET_BLOG_LIST')
export const GET_BLOG_LIST_SUCCESS = createConst('GET_BLOG_LIST_SUCCESS')
export const GET_BLOG_LIST_FAILED = createConst('GET_BLOG_LIST_FAILED')
export const GET_BLOG_LIST_MORE = createConst('GET_BLOG_LIST_MORE')
export const GET_BLOG_LIST_MORE_SUCCESS = createConst('GET_BLOG_LIST_MORE_SUCCESS')
export const GET_BLOG_LIST_MORE_FAILED = createConst('GET_BLOG_LIST_MORE_FAILED')
export function getBlogList({cityName, orderBy = '-date', offset, limit = 20}) {
  return {
    cityName,
    [CALL_API]: {
      path: '/blog/',
      method: 'get',
      query: {
        city_name: cityName === '所有文章' ? '全部' : cityName,
        order_by: orderBy,
        limit,
        offset,
        // blogType: 201,
      },
      sendingType: offset ? GET_BLOG_LIST_MORE : GET_BLOG_LIST,
      successType: offset ? GET_BLOG_LIST_MORE_SUCCESS : GET_BLOG_LIST_SUCCESS,
      errorType: offset ? GET_BLOG_LIST_MORE_FAILED : GET_BLOG_LIST_FAILED,
    }
  }
}

export const TEXT_WEB_SERVER_IP = createConst('TEXT_WEB_SERVER_IP')
export function testWebServerIP() {
  return {
    [CALL_API]: {
      path: '/apitest/debug_ip/',
      method: 'get',
      sendingType: TEXT_WEB_SERVER_IP,
      successType: TEXT_WEB_SERVER_IP,
      errorType: TEXT_WEB_SERVER_IP,
    }
  }
}


export const GET_RECENT_CHART_LIST = createConst('GET_RECENT_CHART_LIST')
export const GET_RECENT_CHART_LIST_SUCCESS = createConst('GET_RECENT_CHART_LIST_SUCCESS')
export const GET_RECENT_CHART_LIST_FAILED = createConst('GET_RECENT_CHART_LIST_FAILED')
export function getRecentChartList({listType, keyword, city, adminName, limit = 10}) {
  return {
    listType,
    keyword,
    city,
    [CALL_API]: {
      path: '/restaurant/explore',
      method: 'get',
      query: omitBy({
        q: keyword,
        city_name: city,
        area_name: adminName,
        order_by: 'popular',
        limit
      }, isNil),
      sendingType: GET_RECENT_CHART_LIST,
      successType: GET_RECENT_CHART_LIST_SUCCESS,
      errorType: GET_RECENT_CHART_LIST_FAILED
    }
  }
}
