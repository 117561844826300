import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/currentUser'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  serverAuthUserNotValid: false,
})

const REDUCER_NODE = 'currentUser'

export default function currentUser(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_MY_PROFILE_SUCCESS:
      return state.merge(action.response.response).set('isLoading', false)

    case ActionType.VERIFY_AUTH_SUCCESS:
      // Success verify auth user
      const { info } = action.response
      if (info.isAuth) {
        return state.merge(action.response.response).set('isLoading', false)
      }
      // Not valid auth user
      return state.set('serverAuthUserNotValid', true)

    case ActionType.CLEAR_USER_DATA:
      return initialState

    case ActionType.USER_LOGIN:
    case ActionType.GENERAL_LOGIN:
    case ActionType.BLOGGER_LOGIN:
      return initialState.set('isLoading', true)

    case ActionType.USER_LOGIN_FAILED:
    case ActionType.GENERAL_LOGIN_FAILED:
    case ActionType.BLOGGER_LOGIN_FAILED:
      return state
        .set('errorMessage', '帳號密碼錯誤或是連線失敗')
        .set('isLoading', false)

    case ActionType.USER_LOGIN_SUCCESS:
    case ActionType.GENERAL_LOGIN_SUCCESS:
    case ActionType.BLOGGER_LOGIN_SUCCESS:
      return state.merge(action.response)

    default:
      return state
  }
}
