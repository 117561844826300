import constantCreator from '@/libs/constantCreator'
import { navigator } from '@/libs/globalUtils'
import { setTimeout } from '@/libs/globalUtils'
import axios from 'axios'
const createConst = constantCreator('geoLocation')

export const GET_CURRENT_POSITION = createConst('GET_CURRENT_POSITION')
export const GET_CURRENT_POSITION_SUCCESS = createConst(
  'GET_CURRENT_POSITION_SUCCESS'
)
export const GET_CURRENT_POSITION_FAILED = createConst(
  'GET_CURRENT_POSITION_FAILED'
)
export function getCurrentPosition() {
  return (dispatch) => {
    const getPositionFromNavigator = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error),
        { maximumAge: 300000 }
      )
    })
    const getPositionFromIP = new Promise((resolve, reject) => {
      setTimeout(() => {
        axios
          .get('https://geoip-db.com/json/')
          .then((response) => {
            resolve({
              timestamp: new Date().getTime(),
              coords: {
                latitude: response.data.latitude,
                longitude: response.data.longitude,
              },
            })
          })
          .catch(() => reject(new Error()))
      }, 1000)
    })

    dispatch({
      type: GET_CURRENT_POSITION,
    })
    Promise.any([getPositionFromNavigator, getPositionFromIP])
      .then((position) =>
        dispatch({
          type: GET_CURRENT_POSITION_SUCCESS,
          position,
        })
      )
      .catch(Promise.AggregateError, (errArray) => {
        let error = { code: 'unknown' }
        errArray.forEach((e) => {
          if (e.code) {
            error = e
          }
        })
        dispatch({
          type: GET_CURRENT_POSITION_FAILED,
          error,
        })
      })
  }
}
