import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/dish'
const ITEMS_PER_PAGE = 20

export const initialState = Immutable.fromJS({
  _hasInit: false,

  dishInfo: {
    isLoading: false,
    data: [],
  },
  currentDishMap: {
    data: {},
    isLoading: false,
  },
  dishPhotosMap: {
    data: [],
    photoCount: 0,
    isLoading: false,
    isLoadingMore: false,
    offset: 0,
  },
  dishReviewsMap: {
    data: [],
    isLoading: false,
    isLoadingMore: false,
    offset: 0,
  },
  recommendMap: Immutable.fromJS({}),
})

const REDUCER_NODE = 'dish'

export default function dish(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_DISH_DETAIL:
      return state.set('dishInfo', initialState.get('dishInfo'))

    case ActionType.GET_DISH_INFO:
      return state
        .setIn(['currentDishMap', 'isLoading'], true)
        .setIn(
          ['currentDishMap', 'data'],
          initialState.getIn(['currentDishMap', 'data'])
        )

    case ActionType.GET_DISH_INFO_SUCCESS:
      const recommendMap = {}
      recommendMap[action.response.response.id] =
        !!action.response.response.recommendedTs
      return state
        .setIn(['currentDishMap', 'isLoading'], false)
        .setIn(
          ['currentDishMap', 'data'],
          Immutable.fromJS(action.response.response)
        )
        .mergeIn(
          ['recommendMap'],
          !!action.response.response.recommendedTs ? recommendMap : {}
        )

    case ActionType.GET_DISH_INFO_FAILED:
      return state.setIn(['currentDishMap', 'isLoading'], false)

    case ActionType.GET_DISH_PHOTOS:
      return state
        .setIn(['dishPhotosMap', 'isLoading'], true)
        .setIn(
          ['dishPhotosMap', 'data'],
          initialState.getIn(['dishPhotosMap', 'data'])
        )

    case ActionType.GET_DISH_PHOTOS_SUCCESS:
      return state
        .setIn(['dishPhotosMap', 'isLoading'], false)
        .setIn(
          ['dishPhotosMap', 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(['dishPhotosMap', 'photoCount'], action.response.info.photoCount)
        .setIn(['dishPhotosMap', 'offset'], ITEMS_PER_PAGE)

    case ActionType.GET_DISH_PHOTOS_FAILED:
      return state.setIn(['dishPhotosMap', 'isLoading'], false)

    case ActionType.GET_DISH_PHOTOS_MORE:
      return state.setIn(['dishPhotosMap', 'isLoadingMore'], true)

    case ActionType.GET_DISH_PHOTOS_MORE_SUCCESS:
      return state
        .setIn(['dishPhotosMap', 'isLoadingMore'], false)
        .setIn(
          ['dishPhotosMap', 'offset'],
          state.getIn(['dishPhotosMap', 'offset']) + ITEMS_PER_PAGE
        )
        .updateIn(['dishPhotosMap', 'data'], (data) =>
          data
            .concat(Immutable.fromJS(action.response.response))
            .toOrderedSet()
            .toList()
        )

    case ActionType.GET_DISH_PHOTOS_MORE_FAILED:
      return state.setIn(['dishPhotosMap', 'isLoadingMore'], false)

    case ActionType.GET_DISH_REVIEWS:
      return state
        .setIn(['dishReviewsMap', 'isLoading'], true)
        .setIn(
          ['dishReviewsMap', 'data'],
          initialState.getIn(['dishReviewsMap', 'data'])
        )

    case ActionType.GET_DISH_REVIEWS_SUCCESS:
      return state
        .setIn(['dishReviewsMap', 'isLoading'], false)
        .setIn(
          ['dishReviewsMap', 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(['dishReviewsMap', 'offset'], ITEMS_PER_PAGE)

    case ActionType.GET_DISH_REVIEWS_FAILED:
      return state.setIn(['dishReviewsMap', 'isLoading'], false)

    case ActionType.GET_DISH_REVIEWS_MORE:
      return state.setIn(['dishReviewsMap', 'isLoadingMore'], true)

    case ActionType.GET_DISH_REVIEWS_MORE_SUCCESS:
      return state
        .setIn(['dishReviewsMap', 'isLoadingMore'], false)
        .setIn(
          ['dishReviewsMap', 'offset'],
          state.getIn(['dishReviewsMap', 'offset']) + ITEMS_PER_PAGE
        )
        .updateIn(['dishReviewsMap', 'data'], (data) =>
          data
            .concat(Immutable.fromJS(action.response.response))
            .toOrderedSet()
            .toList()
        )

    case ActionType.GET_DISH_REVIEWS_MORE_FAILED:
      return state.setIn(['dishReviewsMap', 'isLoadingMore'], false)

    case ActionType.RECOMMEND_DISH:
    case ActionType.UNRECOMMEND_DISH_FAILED:
      return state
        .setIn(['recommendMap', action.dishId], true)
        .updateIn(
          ['currentDishMap', 'data', 'recommendCnt'],
          (recommendCnt) => recommendCnt + 1
        )
        .updateIn(['dishInfo', 'data'], (list) => {
          const index = list.findIndex(
            (data) => data.get('id') === action.dishId
          )
          return list.updateIn(
            [index, 'recommendCnt'],
            (recommendCnt) => recommendCnt + 1
          )
        })

    case ActionType.RECOMMEND_DISH_FAILED:
    case ActionType.UNRECOMMEND_DISH:
      return state
        .setIn(['recommendMap', action.dishId], false)
        .updateIn(
          ['currentDishMap', 'data', 'recommendCnt'],
          (recommendCnt) => recommendCnt - 1
        )
        .updateIn(['dishInfo', 'data'], (list) => {
          const index = list.findIndex(
            (data) => data.get('id') === action.dishId
          )
          return list.updateIn(
            [index, 'recommendCnt'],
            (recommendCnt) => recommendCnt - 1
          )
        })

    case ActionType.SEND_DISH_REVIEW_SUCCESS:
      return state.updateIn(['dishReviewsMap', 'data'], (data) =>
        data
          .unshift(Immutable.fromJS(action.response.response))
          .toOrderedSet()
          .toList()
      )

    case ActionType.ADD_DISH_PHOTO_SUCCESS:
      return state
        .updateIn(['dishPhotosMap', 'data'], (data) =>
          data
            .unshift(Immutable.fromJS(action.response.response))
            .toOrderedSet()
            .toList()
        )
        .updateIn(['dishPhotosMap', 'photoCount'], (data) => data + 1)

    case ActionType.DELETE_DISH_PHOTO_SUCCESS:
      return state
        .updateIn(['dishPhotosMap', 'data'], (data) =>
          data.filter((photoItem) => photoItem.get('id') !== action.photoId)
        )
        .updateIn(['dishPhotosMap', 'photoCount'], (data) => data - 1)

    case ActionType.REPORT_DISH_SUCCESS:
      return state

    case ActionType.GET_DISH_LIST:
      return state.setIn(['dishInfo', 'isLoading'], true)

    case ActionType.GET_DISH_LIST_SUCCESS:
      return state
        .setIn(['dishInfo', 'isLoading'], false)
        .setIn(['dishInfo', 'data'], Immutable.fromJS(action.response.response))

    case ActionType.GET_DISH_LIST_FAILED:
      return state.setIn(['dishInfo', 'isLoading'], false)

    case ActionType.ADD_DISH_SUCCESS:
      return state.updateIn(['dishInfo', 'data'], (list) => {
        return list.push(Immutable.fromJS(action.response.response))
      })

    default:
      return state
  }
}
