import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
const createConst = constantCreator('user')
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'


export const GET_USER_SAVED_PLACE = createConst('GET_USER_SAVED_PLACE')
export const GET_USER_SAVED_PLACE_SUCCESS = createConst(
  'GET_USER_SAVED_PLACE_SUCCESS'
)
export const GET_USER_SAVED_PLACE_FAILED = createConst(
  'GET_USER_SAVED_PLACE_FAILED'
)

export function getUserSavedPlace({ offset, limit, _sps, userId }) {
  let query = { offset, limit }
  if (_sps) {
    query = Object.assign(query, { _sps, userId })
  }
  return {
    [CALL_API]: {
      path: `/saved_place/`,
      method: 'get',
      query,
      sendingType: GET_USER_SAVED_PLACE,
      successType: GET_USER_SAVED_PLACE_SUCCESS,
      errorType: GET_USER_SAVED_PLACE_FAILED,
    },
  }
}

export const GET_USER_SAVED_PLACE_MORE = createConst(
  'GET_USER_SAVED_PLACE_MORE'
)
export const GET_USER_SAVED_PLACE_MORE_SUCCESS = createConst(
  'GET_USER_SAVED_PLACE_MORE_SUCCESS'
)
export const GET_USER_SAVED_PLACE_MORE_FAILED = createConst(
  'GET_USER_SAVED_PLACE_MORE_FAILED'
)

export function getUserSavedPlaceMore({ offset, limit, userId, _sps }) {
  let query = { offset, limit }
  if (_sps) {
    query = Object.assign(query, { _sps, userId })
  }
  return {
    offset,
    [CALL_API]: {
      path: `/saved_place/`,
      method: 'get',
      query,
      sendingType: GET_USER_SAVED_PLACE_MORE,
      successType: GET_USER_SAVED_PLACE_MORE_SUCCESS,
      errorType: GET_USER_SAVED_PLACE_MORE_FAILED,
    },
  }
}

export const GET_USER_BLOG = createConst('GET_USER_BLOG')
export const GET_USER_BLOG_SUCCESS = createConst('GET_USER_BLOG_SUCCESS')
export const GET_USER_BLOG_FAILED = createConst('GET_USER_BLOG_FAILED')

export function getUserBlog({ userId, offset, limit }) {
  return {
    [CALL_API]: {
      path: `/user/${userId}/blogs/`,
      method: 'get',
      query: { offset, limit },
      sendingType: GET_USER_BLOG,
      successType: GET_USER_BLOG_SUCCESS,
      errorType: GET_USER_BLOG_FAILED,
    },
  }
}


export const SAVE_USER_PROFILE = createConst('SAVE_USER_PROFILE')
export const SAVE_USER_PROFILE_SUCCESS = createConst(
  'SAVE_USER_PROFILE_SUCCESS'
)
export const SAVE_USER_PROFILE_FAILED = createConst('SAVE_USER_PROFILE_FAILED')
export function saveUserProfile({
  userId,
  fbPage,
  homePage,
  igId,
  name,
  profileImageUrl,
  coverImageUrl,
}) {
  const body = { name, fbPage, homePage, igId }
  if (profileImageUrl) {
    body.rolePicUrl = profileImageUrl
  }
  if (coverImageUrl) {
    body.coverUrl = coverImageUrl
  }
  return {
    userId,
    name,
    profileImageUrl,
    coverImageUrl,
    [CALL_API]: {
      path: `/user/${userId}/`,
      method: 'post',
      body,
      sendingType: SAVE_USER_PROFILE,
      successType: SAVE_USER_PROFILE_SUCCESS,
      errorType: SAVE_USER_PROFILE_FAILED,
    },
  }
}

export const GET_USER_BLOG_MORE = createConst('GET_USER_BLOG_MORE')
export const GET_USER_BLOG_MORE_SUCCESS = createConst(
  'GET_USER_BLOG_MORE_SUCCESS'
)
export const GET_USER_BLOG_MORE_FAILED = createConst(
  'GET_USER_BLOG_MORE_FAILED'
)

export function getUserBlogMore({ userId, offset, limit }) {
  return {
    offset,
    [CALL_API]: {
      path: `/user/${userId}/blogs/`,
      method: 'get',
      query: { offset, limit },
      sendingType: GET_USER_BLOG_MORE,
      successType: GET_USER_BLOG_MORE_SUCCESS,
      errorType: GET_USER_BLOG_MORE_FAILED,
    },
  }
}

export const GET_USER_DATA = createConst('GET_USER_DATA')
export const GET_USER_DATA_SUCCESS = createConst('GET_USER_DATA_SUCCESS')
export const GET_USER_DATA_FAILED = createConst('GET_USER_DATA_FAILED')

export function getUser({userId}) {
  return {
    [CALL_API]: {
      path: `/user/${userId}/`,
      method: 'get',
      query: {},
      sendingType: GET_USER_DATA,
      successType: GET_USER_DATA_SUCCESS,
      errorType: GET_USER_DATA_FAILED,
    }
  }
}

export const GET_RECENT_CHART_LIST = createConst('GET_RECENT_CHART_LIST')
export const GET_RECENT_CHART_LIST_SUCCESS = createConst('GET_RECENT_CHART_LIST_SUCCESS')
export const GET_RECENT_CHART_LIST_FAILED = createConst('GET_RECENT_CHART_LIST_FAILED')
export function getRecentChartList({listType, keyword, city, adminName, limit = 10}) {
  return {
    listType,
    keyword,
    city,
    [CALL_API]: {
      path: '/restaurant/explore',
      method: 'get',
      query: omitBy({
        q: keyword,
        city_name: city,
        area_name: adminName,
        order_by: 'popular',
        limit
      }, isNil),
      sendingType: GET_RECENT_CHART_LIST,
      successType: GET_RECENT_CHART_LIST_SUCCESS,
      errorType: GET_RECENT_CHART_LIST_FAILED
    }
  }
}
