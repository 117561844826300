import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import { window } from '@/libs/globalUtils'
import * as ActionType from '@/actions/user'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  userData: {},
  blogInfo: {
    data: [],
    offset: 0,
    isFinish: false,
    isLoadingMore: false,
  },
  savedPlaceInfo: {
    data: [],
    offset: 0,
    isFinish: false,
    isLoadingMore: false,
  },
  recentChartList: {
    firstCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
      city: '',
    },
    secondCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
      city: '',
    },
    thirdCategory: {
      data: [],
      ads: [],
      isLoading: false,
      keyword: '',
      city: '',
    },
  },
})

const REDUCER_NODE = 'user'

export default function user(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_USER_DATA_SUCCESS:
      return state.set('userData', Immutable.fromJS(action.response.response))

    case ActionType.SAVE_USER_PROFILE:
      let savedState = state.setIn(['userData', 'displayName'], action.name)
      if (action.profileImageUrl) {
        savedState = savedState.setIn(
          ['userData', 'profilePic'],
          action.profileImageUrl
        )
      }
      if (action.coverImageUrl) {
        savedState = savedState.setIn(
          ['userData', 'coverUrl'],
          action.coverImageUrl
        )
      }
      return savedState

    case ActionType.SAVE_USER_PROFILE_SUCCESS:
      if (action.profileImageUrl) {
        window.location.reload()
      }
      return state.set('userData', Immutable.fromJS(action.response.response))

    case ActionType.GET_USER_SAVED_PLACE_SUCCESS:
      return state
        .setIn(
          ['savedPlaceInfo', 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(
          ['savedPlaceInfo', 'isFinish'],
          action.response.response.length === 0
        )

    case ActionType.GET_USER_SAVED_PLACE_MORE:
      return state.setIn(['savedPlaceInfo', 'isLoadingMore'], true)
    case ActionType.GET_USER_SAVED_PLACE_MORE_SUCCESS:
      return state
        .updateIn(['savedPlaceInfo', 'data'], (list) => {
          return list.concat(Immutable.fromJS(action.response.response))
        })
        .setIn(['savedPlaceInfo', 'offset'], action.offset)
        .setIn(
          ['savedPlaceInfo', 'isFinish'],
          action.response.response.length === 0
        )
        .setIn(['savedPlaceInfo', 'isLoadingMore'], false)

    case ActionType.GET_USER_BLOG_SUCCESS:
      return state
        .setIn(['blogInfo', 'data'], Immutable.fromJS(action.response.response))
        .setIn(['blogInfo', 'isFinish'], action.response.response.length === 0)

    case ActionType.GET_USER_BLOG_MORE:
      return state.setIn(['blogInfo', 'isLoadingMore'], true)

    case ActionType.GET_USER_BLOG_MORE_SUCCESS:
      return state
        .updateIn(['blogInfo', 'data'], (list) => {
          return list.concat(Immutable.fromJS(action.response.response))
        })
        .setIn(['blogInfo', 'offset'], action.offset)
        .setIn(['blogInfo', 'isFinish'], action.response.response.length === 0)
        .setIn(['blogInfo', 'isLoadingMore'], false)

    case ActionType.GET_USER_BLOG_MORE_FAILED:
      return state.setIn(['blogInfo', 'isLoadingMore'], false)

    case ActionType.GET_RECENT_CHART_LIST:
      return state
        .setIn(
          ['recentChartList', action.listType, 'data'],
          initialState.getIn(['recentChartList', action.listType, 'data'])
        )
        .setIn(
          ['recentChartList', action.listType, 'ads'],
          initialState.getIn(['recentChartList', action.listType, 'ads'])
        )
        .setIn(['recentChartList', action.listType, 'isLoading'], true)
        .setIn(['recentChartList', action.listType, 'keyword'], action.keyword)
        .setIn(['recentChartList', action.listType, 'city'], action.city)

    case ActionType.GET_RECENT_CHART_LIST_FAILED:
      return state
        .setIn(['recentChartList', action.listType, 'isLoading'], false)
        .setIn(['recentChartList', action.listType, 'keyword'], '')
        .setIn(['recentChartList', action.listType, 'city'], '')

    case ActionType.GET_RECENT_CHART_LIST_SUCCESS:
      return state
        .setIn(
          ['recentChartList', action.listType, 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(
          ['recentChartList', action.listType, 'ads'],
          Immutable.fromJS(
            !!action.response.info.ads ? action.response.info.ads : []
          )
        )
        .setIn(['recentChartList', action.listType, 'isLoading'], false)

    default:
      return state
  }
}
