import { CALL_API } from 'redux-api-middleman'
import { Router } from '@/routes'
import constantCreator from '@/libs/constantCreator'
import { window } from '@/libs/globalUtils'
const createConst = constantCreator('currentUser')
import config from '@/config'

export const GET_MY_PROFILE = createConst('GET_MY_PROFILE')
export const GET_MY_PROFILE_SUCCESS = createConst('GET_MY_PROFILE_SUCCESS')
export const GET_MY_PROFILE_FAILED = createConst('GET_MY_PROFILE_FAILED')
export function getMyProfile() {
  return {
    [CALL_API]: {
      path: '/user/me',
      method: 'get',
      sendingType: GET_MY_PROFILE,
      successType: GET_MY_PROFILE_SUCCESS,
      errorType: GET_MY_PROFILE_FAILED,
    },
  }
}

export const VERIFY_AUTH = createConst('VERIFY_AUTH')
export const VERIFY_AUTH_SUCCESS = createConst('VERIFY_AUTH_SUCCESS')
export const VERIFY_AUTH_FAILED = createConst('VERIFY_AUTH_FAILED')
export function verifyAuth() {
  return {
    [CALL_API]: {
      path: '/auth/verify',
      method: 'get',
      sendingType: VERIFY_AUTH,
      successType: VERIFY_AUTH_SUCCESS,
      errorType: VERIFY_AUTH_FAILED,
    },
  }
}

export function getBusinessProfileGoDashboard() {
  return {
    [CALL_API]: {
      path: '/user/me',
      method: 'get',
      sendingType: GET_MY_PROFILE,
      successType: GET_MY_PROFILE_SUCCESS,
      errorType: GET_MY_PROFILE_FAILED,
      afterSuccess: () => {
        // Router.replaceRoute('/business/account-settings')
        window.location.href = `/business/account-settings`
      },
    },
  }
}

export const CLEAR_USER_DATA = createConst('CLEAR_USER_DATA')
export function clearUserData() {
  return {
    type: CLEAR_USER_DATA,
  }
}

export function userLogout() {
  return {
    [CALL_API]: {
      url: `${config.ifoodieHost}/api/auth/logout`,
      method: 'post',
      body: {},
      successType: CLEAR_USER_DATA,
    },
  }
}

export const GENERAL_LOGIN = createConst('GENERAL_LOGIN')
export const GENERAL_LOGIN_SUCCESS = createConst('GENERAL_LOGIN_SUCCESS')
export const GENERAL_LOGIN_FAILED = createConst('GENERAL_LOGIN_FAILED')
export function generalLogin({ fbId, accessToken, role = 'blogger', redirect = null }) {
  return {
    [CALL_API]: {
      // Web API
      url: '/webapi/fb_auth',
      method: 'post',
      body: { fbId, accessToken },
      sendingType: GENERAL_LOGIN,
      successType: GENERAL_LOGIN_SUCCESS,
      errorType: GENERAL_LOGIN_FAILED,
      decamelizeRequest: false,
      afterSuccess: ({ dispatch, response }) => {
        if (response) {
          if (redirect) {
            Router.replaceRoute(redirect)
          } else if (role == 'blogger') {
            Router.replaceRoute('/blogger/publish')
          } else if (role == 'business') {
            Router.replaceRoute('/business/account-settings')
          } else {
            Router.replaceRoute('/me')
          }
          console.log('success login')
        } else {
          dispatch({ type: USER_LOGIN_FAILED })
        }
      },
    },
  }
}

export const USER_LOGIN = createConst('USER_LOGIN')
export const USER_LOGIN_SUCCESS = createConst('USER_LOGIN_SUCCESS')
export const USER_LOGIN_FAILED = createConst('USER_LOGIN_FAILED')
export function userLogin({ fbId, accessToken }) {
  return {
    [CALL_API]: {
      // Web API
      url: '/webapi/fb_auth',
      method: 'post',
      body: { fbId, accessToken },
      sendingType: USER_LOGIN,
      successType: USER_LOGIN_SUCCESS,
      errorType: USER_LOGIN_FAILED,
      decamelizeRequest: false,
      afterSuccess: ({ dispatch, response }) => {
        if (response) {
          Router.replaceRoute('/me')
          console.log('success login')
        } else {
          dispatch({ type: USER_LOGIN_FAILED })
        }
      },
    },
  }
}

export const BLOGGER_LOGIN = createConst('BLOGGER_LOGIN')
export const BLOGGER_LOGIN_SUCCESS = createConst('BLOGGER_LOGIN_SUCCESS')
export const BLOGGER_LOGIN_FAILED = createConst('BLOGGER_LOGIN_FAILED')
export function bloggerLogin({ fbId, accessToken, redirect = null }) {
  return {
    [CALL_API]: {
      // Web API
      url: '/webapi/fb_auth',
      method: 'post',
      body: { fbId, accessToken },
      sendingType: BLOGGER_LOGIN,
      successType: BLOGGER_LOGIN_SUCCESS,
      errorType: BLOGGER_LOGIN_FAILED,
      decamelizeRequest: false,
      afterSuccess: ({ dispatch, response }) => {
        if (response) {
          if (redirect) {
            window.location.href = redirect
          } else {
            Router.replaceRoute('/blogger/publish')
          }
          console.log('success login')
        } else {
          dispatch({ type: BLOGGER_LOGIN_FAILED })
        }
      },
    },
  }
}

export function passwordLogin({
  username,
  password,
  redirect = null,
  destination = null,
}) {
  return {
    [CALL_API]: {
      url: `${config.ifoodieHost}/api/auth/signin/`,
      method: 'post',
      body: { username, password, rememberMe: true },
      sendingType: USER_LOGIN,
      successType: USER_LOGIN_SUCCESS,
      errorType: USER_LOGIN_FAILED,
      afterSuccess: ({ dispatch, response }) => {
        if (response.success) {
          if (redirect) {
            window.location.href = redirect
          } else if (destination == 'users') {
            // Use window.location.href to prevent currentUser data fail
            window.location.href = `/u/${response.response.userId}/`
          } else {
            dispatch(getBusinessProfileGoDashboard())
          }
        } else {
          dispatch({ type: USER_LOGIN_FAILED })
        }
      },
    },
  }
}
