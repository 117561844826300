export default {
  appVersion: '1.8.3',
  env: process.env.NODE_ENV,
  staticVersion: '1.0.9',
  buildMode: process.env.BUILD_MODE,
  coreApiEndpoint: process.env.NEXT_PUBLIC_CORE_API_ENDPOINT,
  ifoodieHost: process.env.NEXT_PUBLIC_IFOODIE_HOST,
  // Front-end only
  googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  googleAdsenseClientId: 'ca-pub-9874927516416876',
  facebookAppId: '245067605649260',
  optadminId: '5267c4b140d18d08a0fea9f4',
  superUserIds: [
    '5267c48440d18d08981d4163',
    '5267c4b140d18d08a0fea9f4',
    '534177ef40d18d5665e92d1e',
  ],
  mapboxApiAccessToken: process.env.MAP_BOX_ACCESS_TOKEN,
}
