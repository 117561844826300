let _document = (typeof window == 'undefined') ? null : window.document
let _confirm = (typeof window == 'undefined') ? null : window.confirm
let _window = (typeof window == 'undefined') ? null : window
let _setTimeout = (typeof window == 'undefined') ? null : window.setTimeout
let _clearTimeout = (typeof window == 'undefined') ? null : window.clearTimeout
let _ga = typeof window !== 'undefined' && window.ga
let _navigator = (typeof window == 'undefined') ? null : window.navigator
let _localStorage = null

// for incognito mode, access window.localStorage raise error
try {
  _localStorage = typeof window == 'undefined' ? null : window.localStorage
} catch (error) {
  console.log('local storage initial fail')
}

let _WebViewBridge = (typeof window == 'undefined') ? null : {
  sendFromApp: data => {
    const event = new CustomEvent('appMessage', { detail: { data } })
    window.dispatchEvent(event)
  },
  postAction: action => {
    if(window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify(action))
    } else if(
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.iOS &&
      window.webkit.messageHandlers.iOS.postMessage
    ) {
      window.webkit.messageHandlers.iOS.postMessage(action)
    }
  }
}

if(typeof window !== 'undefined') {
  window.WebViewBridge = _WebViewBridge
}

export { _document as document }
export { _window as window }
export { _confirm as confirm }
export { _setTimeout as setTimeout}
export { _clearTimeout as clearTimeout}
export { _ga as ga }
export { _navigator as navigator }
export { _WebViewBridge as WebViewBridge }
export { _localStorage as localStorage }