import { createStore, applyMiddleware } from 'redux'
import { fromJS } from 'immutable'
import Immutable from 'immutable'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createWrapper } from 'next-redux-wrapper'
import createApiMiddleware from 'redux-api-middleman'
import rootReducer from '../reducers'
import localStorageMiddleware from './middlewares/localStorage'
import config from '@/config'
// Do not use config - inconsistant for client side

const baseUrl = config.coreApiEndpoint

function createMiddlewares({ sessionId }) {
  let middlewares = [
    thunkMiddleware,
    localStorageMiddleware,
    createApiMiddleware({
      baseUrl,
      timeout: 11500,
      generateDefaultParams: () => {
        let params = {
          headers: {},
        }
        if (sessionId) {
          params.headers.Cookie = `sessionid=${sessionId}`
        }
        return params
      },
    }),
  ]
  return middlewares
}

function getToken(req) {
  return {
    refreshToken: req ? req.refreshToken : '',
    sessionId: req && req.cookies ? req.cookies.sessionid : '',
  }
}

const makeStore = (context) => {
  const { ctx } = context
  let req = {}
  if (ctx) {
    req = ctx.req
  }
  const { sessionId } = getToken(req)
  const middlewares = createMiddlewares({
    sessionId,
  })
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  )
  return store
}

export const wrapper = createWrapper(makeStore, {
  serializeState: (state) => Immutable.fromJS(state).toJS(),
  deserializeState: (state) => fromJS(state),
})
