import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/checkins'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  checkinInfo: {
    offset: 0,
    data: [],
    isFinish: false,
  },
  imageUploadStatus: {
    progress: 0,
    imgUrl: '',
  },
  likeStatusList: [],
  replyMessage: {
    isLoading: false,
    isSuccess: false,
  },
})

const REDUCER_NODE = 'checkins'

export default function checkins(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.GET_CHECKIN_INFO:
      return state.set('checkinInfo', initialState.get('checkinInfo'))

    case ActionType.GET_CHECKIN_INFO_SUCCESS:
      return state
        .setIn(
          ['checkinInfo', 'data'],
          Immutable.fromJS(action.response.response)
        )
        .setIn(
          ['checkinInfo', 'isFinish'],
          action.response.response.length === 0
        )
        .update('likeStatusList', (data) =>
          data
            .concat(
              action.response.response
                .filter((checkinItem) => !!checkinItem.isLike)
                .map((checkinItem) => checkinItem.id)
            )
            .toOrderedSet()
            .toList()
        )

    case ActionType.GET_CHECKIN_INFO_MORE_SUCCESS:
      return state
        .updateIn(['checkinInfo', 'data'], (list) => {
          return list.concat(Immutable.fromJS(action.response.response))
        })
        .setIn(['checkinInfo', 'offset'], action.offset)
        .setIn(
          ['checkinInfo', 'isFinish'],
          action.response.response.length === 0
        )

    case ActionType.UPDATE_UPLOAD_IMAGE_PROGRESS:
      return state.setIn(['imageUploadStatus', 'progress'], action.progress)

    case ActionType.UPLOAD_IMAGE_SUCCESS:
      return state
        .setIn(['imageUploadStatus', 'imgUrl'], action.imgUrl)
        .setIn(['imageUploadStatus', 'progress'], 0)

    case ActionType.ADD_CHECKIN_SUCCESS:
      return state
        .setIn(
          ['checkinInfo', 'data'],
          state
            .getIn(['checkinInfo', 'data'])
            .filter((item) => item.get('id') !== action.response.response.id)
        )
        .updateIn(['checkinInfo', 'data'], (list) => {
          return list.unshift(Immutable.fromJS(action.response.response))
        })
        .setIn(['imageUploadStatus', 'imgUrl'], '')

    case ActionType.ADD_LIKE:
      return state.update('likeStatusList', (data) =>
        Immutable.fromJS(data.concat(action.checkinId).toOrderedSet().toList())
      )

    case ActionType.ADD_LIKE_FAILED:
      return state.update('likeStatusList', (data) =>
        data.filter((checkinId) => checkinId !== action.checkinId)
      )

    case ActionType.REMOVE_LIKE:
      return state.update('likeStatusList', (data) =>
        data.filter((checkinId) => checkinId !== action.checkinId)
      )

    case ActionType.REMOVE_LIKE_FAILED:
      return state.update('likeStatusList', (data) =>
        data.concat(action.checkinId).toOrderedSet().toList()
      )

    case ActionType.REPLY_CHECKIN:
      return state
        .setIn(['replyMessage', 'isLoading'], true)
        .setIn(['replyMessage', 'isSuccess'], false)

    case ActionType.REPLY_CHECKIN_SUCCESS:
      return state
        .setIn(['replyMessage', 'isLoading'], false)
        .setIn(['replyMessage', 'isSuccess'], true)
        .updateIn(['checkinInfo', 'data'], (list) => {
          return list.map((checkinItem) => {
            if (checkinItem.get('id') === action.checkinId) {
              const newItem = checkinItem.set(
                'ownerReply',
                Immutable.fromJS(action.response.response)
              )
              return newItem
            } else {
              return checkinItem
            }
          })
        })

    case ActionType.REPLY_CHECKIN_FAILED:
      return state
        .setIn(['replyMessage', 'isLoading'], false)
        .setIn(['replyMessage', 'isSuccess'], false)

    default:
      return state
  }
}
