import { CALL_API } from 'redux-api-middleman'
import constantCreator from '@/libs/constantCreator'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

const createConst = constantCreator('business')

export const CLEAR_BUSINESS_DATA = createConst('CLEAR_BUSINESS_DATA')
export function clearBusinessData() {
  return {
    type: CLEAR_BUSINESS_DATA
  }
}


export const GET_BUSINESS_RESTAURANTS_SUCCESS = createConst('GET_BUSINESS_RESTAURANTS_SUCCESS')
export function getBusinessRestaurants({ restId }) {
  return {
    [CALL_API]: {
      path: '/business/',
      method: 'get',
      query: omitBy({ restId }, isNil),
      successType: GET_BUSINESS_RESTAURANTS_SUCCESS,
    },
  }
}

export const GET_RESTAURANT_CARDS = createConst('GET_RESTAURANT_CARDS')
export const GET_RESTAURANT_CARDS_SUCCESS = createConst('GET_RESTAURANT_CARDS_SUCCESS')
export function getRestaurantCards({ restaurantId }) {
  return {
    [CALL_API]: {
      path: `/business/${restaurantId}/cards/`,
      method: 'get',
      sendingType: GET_RESTAURANT_CARDS,
      successType: GET_RESTAURANT_CARDS_SUCCESS
    }
  }
}

export const UPDATE_CARD_STATE_SUCCESS = createConst('UPDATE_CARD_STATE_SUCCESS')
export function updateCardState({ cardId, state }) {
  return {
    [CALL_API]: {
      path: '/business/update_card_state/',
      method: 'post',
      body: {
        publishState: state,
        cardId
      },
      successType: UPDATE_CARD_STATE_SUCCESS
    }
  }
}

export const CREATE_CARD = createConst('CREATE_CARD')
export const CREATE_CARD_SUCCESS = createConst('CREATE_CARD_SUCCESS')
export const CREATE_CARD_FAILED = createConst('CREATE_CARD_FAILED')
export function createCard({ restaurantId, params }) {
  return {
    [CALL_API]: {
      path: `/business/${restaurantId}/cards`,
      method: 'post',
      body: omitBy(params, isNil),
      sendingType: CREATE_CARD,
      successType: CREATE_CARD_SUCCESS,
      errorType: CREATE_CARD_FAILED
    }
  }
}

export const BUSINESS_APPLY = createConst('BUSINESS_APPLY')
export const BUSINESS_APPLY_SUCCESS = createConst('BUSINESS_APPLY_SUCCESS')
export const BUSINESS_APPLY_FAILED = createConst('BUSINESS_APPLY_FAILED')
export function businessApply({ email, restaurant, address, phone, name }) {
  return {
    [CALL_API]: {
      path: '/business/apply',
      method: 'post',
      body: {
        email,
        restaurantName: restaurant,
        restaurantAddress: address,
        ownerName: name,
        mobile: phone
      },
      sendingType: BUSINESS_APPLY,
      successType: BUSINESS_APPLY_SUCCESS,
      errorType: BUSINESS_APPLY_FAILED
    }
  }
}

export const GET_APPLICATION = createConst('GET_APPLICATION')
export const GET_APPLICATION_SUCCESS = createConst('GET_APPLICATION_SUCCESS')
export const GET_APPLICATION_FAILED = createConst('GET_APPLICATION_FAILED')
export function getApplication({ applyId }) {
  return {
    applyId,
    [CALL_API]: {
      path: `/business/apply/${applyId}`,
      method: 'get',
      sendingType: GET_APPLICATION,
      successType: GET_APPLICATION_SUCCESS,
      errorType: GET_APPLICATION_FAILED
    }
  }
}

export const CONFIRM_APPLICATION = createConst('CONFIRM_APPLICATION')
export const CONFIRM_APPLICATION_SUCCESS = createConst('CONFIRM_APPLICATION_SUCCESS')
export const CONFIRM_APPLICATION_FAILED = createConst('CONFIRM_APPLICATION_FAILED')
export function confirmApplication({ restaurantId, applyId, onSuccess, onError }) {
  return {
    applyId,
    [CALL_API]: {
      path: `/business/apply/${applyId}/confirm`,
      method: 'post',
      body: {
        restaurantId
      },
      sendingType: CONFIRM_APPLICATION,
      successType: CONFIRM_APPLICATION_SUCCESS,
      errorType: CONFIRM_APPLICATION_FAILED,
      afterSuccess: onSuccess,
      afterError: onError
    }
  }
}