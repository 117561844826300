
import Cookies from 'universal-cookie'

export default {
  config({ req }) {
    this.req = req
    this.cookies = null
  },
  getCookies() {
    if (!this.cookies) {
      if (this.req) {
        this.cookies = new Cookies(this.req.cookies, { path: '/' })
      } else {
        this.cookies = new Cookies(null, { path: '/' })
      }
    }
    return this.cookies
  },
  get(key) {
    return this.getCookies().get(key)
  },
  set(key, value, options) {
    this.getCookies().set(key, value, options)
  },
}



