import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/home'
import * as BlogActionType from '@/actions/blogs'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  newBlogs: [],
  checkinList: [],
  weeklyHot: [],
  categoryBlockList: [],
  recentPosts: [],
})

const REDUCER_NODE = 'home'

export default function home(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case BlogActionType.GET_NEW_BLOGS_SUCCESS:
      const blogs = Immutable.fromJS(action.response.response)
        .sortBy(Math.random)
        .take(3)
      return state.set('newBlogs', blogs)

    case ActionType.GET_WEEKLY_HOT_RESTAURANTS_SUCCESS:
      return state.set('weeklyHot', Immutable.fromJS(action.response.response))

    case ActionType.GET_RECENT_USERCHECKIN_SUCCESS:
      return state.set(
        'checkinList',
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_CATEGORY_BLOCK_LIST_SUCCESS:
      return state.set(
        'categoryBlockList',
        Immutable.fromJS(action.response.response)
      )

    case ActionType.GET_RECENT_POST_SUCCESS:
      return state.set(
        'recentPosts',
        Immutable.fromJS(action.response.response)
      )

    default:
      return state
  }
}
