import Immutable from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
import * as ActionType from '@/actions/auth'

export const initialState = Immutable.fromJS({
  _hasInit: false,

  isLoginModalOpen: false,
})

const REDUCER_NODE = 'auth'

export default function auth(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      if (state.get('_hasInit')) {
        return state
      }
      return state.merge(action.payload.get(REDUCER_NODE)).set('_hasInit', true)

    case ActionType.SET_LOGIN_MODAL_OPEN_STATUS:
      return state.set('isLoginModalOpen', action.isOpen)

    default:
      return state
  }
}
